<template>
    <v-dialog v-model="dialogWordButton" width="auto" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-icon small class="mr-1" @click="dialogWordButton = true" v-bind="attrs" v-on="on">
                mdi-file-word-outline
            </v-icon>
        </template>

        <v-card>
            <v-card-title class="headline">
                <span>Angebotsgenerierung </span>
                <v-spacer></v-spacer>
                <v-icon @click="closeWordDialog()" class="ml-3 py-0">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5 pb-0">
                Bitte geben Sie die Anzahl der Leistungsmodule für das Angebot ein:
                <v-form v-model="isFormValid">
                    <v-text-field ref="performanceModuleNumber" v-model="performanceModuleNumber" type="number" :min="1"
                        :max="9" label="Anzahl der Leistungsmodule *" :rules="ruleOneDigitNumber"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeWordDialog()" outlined>
                    Zurück
                </v-btn>
                <v-btn :disabled="!isFormValid" color="blue darken-1" outlined text
                    @click="generateDocument(element.id)">Angebot generieren</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { collection } from "@/services";

export default {
    name: "GenerateDocument",
    props: ["element"],
    data: () => ({
        dialogWordButton: false,
        dialog: false,
        performanceModuleNumber: 1,
        ruleOneDigitNumber: [
            (v) => !!v || "Das Feld darf nicht leer sein.",
            (v) =>
                /^[1-9]$/.test(v) ||
                "Es ist nur eine Zifferstelle (von 1 bis 9) erlaubt.",
        ],
        isFormValid: false,
    }),

    methods: {
        async generateDocument(id) {
            const payload = {
                opportunityID: id,
                performanceModules: this.performanceModuleNumber
            }
            await collection.generateDocument(payload);
            this.dialogWordButton = false;
            //set performanceModuleNumber back to default value
            this.performanceModuleNumber = 1;
        },

        closeWordDialog() {
            this.dialogWordButton = false;
            //set performanceModuleNumber back to default value
            this.performanceModuleNumber = 1;
        }
    }
}
</script>

<style></style>