var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sx-tooltip",
        { attrs: { text: "Bearbeiten" } },
        [
          _vm.getCiscoGroup
            ? _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canEditOpportunity,
                      expression: "canEditOpportunity",
                    },
                  ],
                  staticClass: "mr-1",
                  attrs: { small: "", "data-cy": "edit-opportunity" },
                  on: {
                    click: function ($event) {
                      return _vm.editItem()
                    },
                  },
                },
                [_vm._v(" mdi-pencil ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "sx-tooltip",
        { attrs: { text: "Bearbeiten" } },
        [
          _vm.getCiscoSibeGroup
            ? _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canEditOpportunity,
                      expression: "canEditOpportunity",
                    },
                  ],
                  staticClass: "mr-1",
                  attrs: { small: "", "data-cy": "edit-opportunity" },
                  on: {
                    click: function ($event) {
                      return _vm.editItem()
                    },
                  },
                },
                [_vm._v(" mdi-pencil ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "sx-tooltip",
        { attrs: { text: "Anschauen" } },
        [
          _vm.getCiscoGroup || _vm.getCiscoSibeGroup
            ? _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.seeOpportunityDetails,
                      expression: "seeOpportunityDetails",
                    },
                  ],
                  staticClass: "mr-1",
                  attrs: {
                    small: "",
                    id: "opportunity_view_" + _vm.params.data.id,
                  },
                  on: {
                    click: function ($event) {
                      _vm.editItem(), (_vm.isSeeDetails = true)
                    },
                  },
                },
                [_vm._v(" mdi-eye ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "sx-tooltip",
        { attrs: { text: "Anschauen" } },
        [
          _vm.getComputacenterGroup
            ? _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: {
                    id: "opportunity_view_" + _vm.params.data.id,
                    small: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editItem()
                    },
                  },
                },
                [_vm._v(" mdi-eye ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "sx-tooltip",
        { attrs: { text: "mit neuer Version ersetzen" } },
        [
          _vm.checkVersioning()
            ? _c("Versioning", { attrs: { element: _vm.params.data } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "sx-tooltip",
        { attrs: { text: "Klon erstellen" } },
        [
          _vm.getComputacenterGroup && !_vm.isReplaced
            ? _c("Clone", { attrs: { element: _vm.params.data } })
            : _vm._e(),
        ],
        1
      ),
      !_vm.getComputacenterGroup && _vm.checkEmailButton()
        ? _c(
            "sx-tooltip",
            { attrs: { text: "E-Mail Programm öffnen" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onOpenMail(_vm.params.data)
                    },
                  },
                },
                [_vm._v(" mdi-email-outline ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.getComputacenterGroup && _vm.checkEmailButton()
        ? _c(
            "sx-tooltip",
            { attrs: { text: "E-Mail an Dienstverträge-Mailbox senden" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      _vm.setCheckRelease(_vm.generell_dv)
                      _vm.onOpenMail(_vm.params.data)
                    },
                  },
                },
                [_vm._v(" $iconEmailDv ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "sx-tooltip",
        { attrs: { text: "Angebot generieren" } },
        [
          _vm.getComputacenterGroup
            ? _c("GenerateDocument", { attrs: { element: _vm.params.data } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialog,
              expression: "dialog",
            },
          ],
          attrs: { width: "55rem", scrollable: "", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c(
                "v-card",
                {
                  attrs: { elevation: "0" },
                  on: {
                    "&click": function ($event) {
                      return _vm.closeCalendar.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.formTitle))]),
                      _c("span", [
                        _vm._v("- " + _vm._s(_vm.params.data.version)),
                      ]),
                      _c("v-spacer"),
                      _vm.isReplaced
                        ? _c("span", { staticClass: "isReplaced" }, [
                            _vm._v("ersetzt"),
                          ])
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm.opportunity.isSecret
                        ? _c("v-checkbox", {
                            staticClass: "mt-0 mr-3 py-0 secret editSecret",
                            attrs: {
                              label: "Geheimschutz",
                              disabled: "",
                              readonly: "",
                            },
                            model: {
                              value: _vm.opportunity.isSecret,
                              callback: function ($$v) {
                                _vm.$set(_vm.opportunity, "isSecret", $$v)
                              },
                              expression: "opportunity.isSecret",
                            },
                          })
                        : _vm._e(),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-icon",
                        { staticClass: "ml-3 py-0", on: { click: _vm.close } },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: {
                            "lazy-validation": "",
                            readonly:
                              _vm.formTitle === "Opportunity bearbeiten" &&
                              _vm.getComputacenterGroup &&
                              _vm.params.data.isEdited,
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 pl-0",
                                      attrs: { cols: "12", sm: "6", md: "6" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Opportunity Name",
                                          disabled:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          rules: _vm.ruleRequiredText,
                                          readonly:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.opportunity.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.opportunity,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "opportunity.name",
                                        },
                                      }),
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "item-text": "name",
                                          items: _vm.getCustomers,
                                          disabled:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          readonly:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          rules: _vm.ruleRequiredText,
                                          label: "Kunde",
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.opportunity.customerName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.opportunity,
                                              "customerName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "opportunity.customerName",
                                        },
                                      }),
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "min-width": "0",
                                            "close-on-click": false,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          staticClass: "text",
                                                          attrs: {
                                                            readonly: "",
                                                            disabled:
                                                              _vm.formTitle ===
                                                              "Opportunity bearbeiten",
                                                            label:
                                                              "Start Datum *",
                                                            outlined: "",
                                                            dense: "",
                                                            value:
                                                              _vm.startDateFormatted,
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3801844200
                                          ),
                                          model: {
                                            value: _vm.isMenuStartDate,
                                            callback: function ($$v) {
                                              _vm.isMenuStartDate = $$v
                                            },
                                            expression: "isMenuStartDate",
                                          },
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: { locale: "de" },
                                            on: {
                                              input: function ($event) {
                                                _vm.isMenuStartDate = false
                                              },
                                            },
                                            model: {
                                              value: _vm.opportunity.startDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.opportunity,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "opportunity.startDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          readonly: _vm.params.data.isEdited,
                                          suffix: "Monate",
                                          disabled:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          label: "Dauer *",
                                          rules: _vm.ruleNumberOrFloat,
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.opportunity.duration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.opportunity,
                                              "duration",
                                              $$v
                                            )
                                          },
                                          expression: "opportunity.duration",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 pl-0",
                                      attrs: { cols: "12", sm: "6", md: "6" },
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          name: "input-7-4",
                                          height: "14.8rem",
                                          readonly:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          disabled:
                                            _vm.formTitle ===
                                            "Opportunity bearbeiten",
                                          label: "Beschreibung der Opportunity",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.opportunity.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.opportunity,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "opportunity.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 pl-0",
                                      attrs: { cols: "12", sm: "6", md: "6" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.getEditableStates,
                                          readonly: _vm.params.data.isEdited,
                                          rules: _vm.ruleRequiredText,
                                          "item-value": "name",
                                          "item-text": "name",
                                          disabled: !_vm.canSelect,
                                          "data-cy": "opportunity-state",
                                          label: "Status *",
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.inputState,
                                          callback: function ($$v) {
                                            _vm.inputState = $$v
                                          },
                                          expression: "inputState",
                                        },
                                      }),
                                      _c("v-textarea", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.inputState ||
                                              _vm.opportunity.stateDescription,
                                            expression:
                                              "inputState || opportunity.stateDescription",
                                          },
                                        ],
                                        attrs: {
                                          outlined: "",
                                          readonly: _vm.params.data.isEdited,
                                          name: "input-7-4",
                                          label: "Kommentar Cisco",
                                          rules:
                                            _vm.inputState === "Abgelehnt"
                                              ? _vm.ruleRequiredText
                                              : [],
                                          disabled:
                                            _vm.formTitle ===
                                              "Neue Opportunity" ||
                                            (_vm.getComputacenterGroup
                                              ? true
                                              : false &&
                                                _vm.formTitle ===
                                                  "Opportunity bearbeiten") ||
                                            _vm.isReplaced ||
                                            _vm.params.data.isEdited,
                                          "data-cy":
                                            "opportunity-state-comment",
                                          dense: "",
                                        },
                                        model: {
                                          value:
                                            _vm.opportunity.stateDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.opportunity,
                                              "stateDescription",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "opportunity.stateDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#bdbdbd",
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [_vm._v("Erstellt von: ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-1",
                                                  staticStyle: {
                                                    color: "#757575",
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.opportunity.createdBy
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "6",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#bdbdbd",
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [_vm._v("Erstellt am: ")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-1",
                                                  staticStyle: {
                                                    color: "#757575",
                                                    "font-size": "0.9rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formattedCreatedAt
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 pl-0",
                                      attrs: { cols: "12", sm: "6", md: "6" },
                                    },
                                    [
                                      _c("span", { staticClass: "ml-3" }, [
                                        _vm._v("* Pflichtfelder"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { staticClass: "mt-4" }),
                      _c("ServiceCard", {
                        ref: "serviceCard",
                        attrs: {
                          isEditing: _vm.formTitle === "Opportunity bearbeiten",
                          readonly: _vm.formTitle === "Opportunity bearbeiten",
                          opportunity: _vm.opportunity,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "blue darken-1",
                            text: "",
                            outlined: "",
                          },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" Zurück ")]
                      ),
                      (_vm.getCiscoGroup && !_vm.isReplaced) ||
                      (_vm.getCiscoSibeGroup && _vm.params.data.isSecret)
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.params.data.isEdited &&
                                    !_vm.isSeeDetails,
                                  expression:
                                    "!params.data.isEdited && !isSeeDetails",
                                },
                              ],
                              attrs: {
                                color: "blue darken-1",
                                text: "",
                                outlined: "",
                                "data-cy": "opportunity-send-update-btn",
                              },
                              on: { click: _vm.openDialogSendRequest },
                            },
                            [
                              _c("span", [_vm._v("Absenden")]),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v("mdi-send"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _vm.dialogSendRequest
                              ? _c(
                                  "v-dialog",
                                  {
                                    attrs: { width: "500" },
                                    model: {
                                      value: _vm.dialogSendRequest,
                                      callback: function ($$v) {
                                        _vm.dialogSendRequest = $$v
                                      },
                                      expression: "dialogSendRequest",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "title sx-vertical-layout",
                                            staticStyle: { color: "red" },
                                            attrs: {
                                              "data-cy":
                                                "send-updated-opportunity-dialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "icon-center",
                                                attrs: { color: "red" },
                                              },
                                              [_vm._v("mdi-alert-outline")]
                                            ),
                                            _vm._v(
                                              " Wollen Sie die Opportunity absenden? "
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.dialogSendRequest = false
                                                  },
                                                },
                                              },
                                              [_vm._v(" Abbrechen ")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                  "data-cy":
                                                    "sure-to-send-updated-opportunity",
                                                },
                                                on: { click: _vm.save },
                                              },
                                              [_vm._v(" Ja ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }