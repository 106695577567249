<template>
    <v-dialog v-model="dialog" width="auto" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
                <v-icon color="error">mdi-exclamation-thick</v-icon>Fehler melden
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                <v-icon color="error">mdi-exclamation-thick</v-icon>&nbsp;Fehler melden
                <v-spacer></v-spacer>
                <v-icon @click="dialog = false" class="ml-3 py-0">mdi-close</v-icon>
            </v-card-title>
            <v-card-text class="pt-5 pb-0">
                <div>
                    <p>Gibt es Fehler in der Bedienung der Datenbank? </p>
                    <p>Gibt es Änderungen bei den Verantwortlichen der Kunden?</p>
                    <p>Gibt es Wünsche oder Anregungen?</p>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" outlined text @click="dialog = false">
                    Zurück
                </v-btn>
                <v-btn color="blue darken-1" outlined text :href="mailTo">E-mail senden
                    <v-icon right dark>mdi-send</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "ErrorReport",

    data: () => ({
        dialog: false,
    }),

    computed: {
        mailTo() {
            return "mailto:MB.FCO-Vertrieb@computacenter.com?subject=Fehler in der DL-Datenbank&cc=" + this.$store.state.userInfo.email;
        }
    },

    methods: {
    }
}

</script>