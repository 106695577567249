var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "opportunity-table-toolbar" },
        [
          _c(
            "v-col",
            { attrs: { cols: "8", md: "3", sm: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  id: "filter-text-box",
                  placeholder: "Suche...",
                  "append-icon": "mdi-magnify",
                  dense: "",
                  clearable: "",
                  outlined: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.searchOpportunity()
                  },
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "opportunity-filters" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive("meine"),
                                    "filter-hidden":
                                      _vm.$store.getters[
                                        "user/getCiscoGroup"
                                      ] ||
                                      _vm.$store.getters[
                                        "user/getCiscoSibeGroup"
                                      ],
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "meine"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-account")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("meine")
                          ? "Alle Opportunities anzeigen"
                          : "Nur meine Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "neu-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive("neu"),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter("neu")
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Neu ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("neu")
                          ? "Alle Opportunities anzeigen"
                          : "Nur neue Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "qualifiziert-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive(
                                        "qualifiziert"
                                      ),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "qualifiziert"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Qual ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("qualifiziert")
                          ? "Alle Opportunities anzeigen"
                          : "Nur qualifizierte Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "abgelehnt-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive(
                                        "abgelehnt"
                                      ),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "abgelehnt"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Abg ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("abgelehnt")
                          ? "Alle Opportunities anzeigen"
                          : "Nur abgelehnte Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "bitte-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive(
                                        "bitteumfreigabe"
                                      ),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "bitteumfreigabe"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Bitte ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("bitteumfreigabe")
                          ? "Alle Opportunities anzeigen"
                          : 'Nur Opportunities mit "Bitte um Freigabe" zeigen'
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "frei-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive(
                                        "freigegeben"
                                      ),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "freigegeben"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Frei ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("freigegeben")
                          ? "Alle Opportunities anzeigen"
                          : "Nur freigegebene Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "gewonnen-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive("gewonnen"),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "gewonnen"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Gew ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("gewonnen")
                          ? "Gewonnen-Filter aufheben"
                          : "Nur gewonnene Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "verloren-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive("verloren"),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "verloren"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Verl ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("verloren")
                          ? "Verloren-Filter aufheben"
                          : "Nur verlorene Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  class: {
                                    "ersetzt-button": true,
                                    "filter-toggled":
                                      _vm.isOpportunityFilterActive("ersetzt"),
                                  },
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "ersetzt"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" !Ers ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isOpportunityFilterActive("ersetzt")
                          ? "Verloren-Filter aufheben"
                          : "Nur ersetzte Opportunities zeigen"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { dark: "", outlined: "" },
                                  on: {
                                    click: function () {
                                      return _vm.toggleOpportunityFilter(
                                        "reset"
                                      )
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" Filter zurücksetzen ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "new-opportunity-button" },
            [_c("NewOpportunity", { ref: "newopportunity" })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("ag-grid-vue", {
            ref: "opportunitiesTable",
            staticClass: "ag-theme-alpine opptable",
            attrs: {
              columnDefs: _vm.columnDefs,
              rowData: _vm.oppAndServices,
              gridOptions: _vm.gridOptions,
              defaultColDef: _vm.defaultColDef,
              enableCellChangeFlash: true,
              excelStyles: _vm.excelStyles,
              localeText: _vm.localeText,
              icons: _vm.icons,
              masterDetail: true,
              loadingOverlayComponentFramework: _vm.loadingOverlayComponent,
              defaultExcelExportParams: _vm.defaultExcelExportParams,
              detailCellRendererParams: _vm.detailCellRendererParams,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
          _c(
            "v-row",
            { staticClass: "ml-1 mt-5 mr-1 mb-2" },
            [
              _c("EmailDialog", { ref: "emaildialog" }),
              _c("div", { staticClass: "ml-3" }, [_c("ErrorReport")], 1),
              _c("v-spacer"),
              _c(
                "v-btn",
                { on: { click: _vm.excelDownload } },
                [
                  _c("v-icon", { attrs: { color: "green" } }, [
                    _vm._v("mdi-microsoft-excel"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "overflow-hidden history-dialog",
            persistent: "",
          },
          model: {
            value: _vm.dialogWonLost,
            callback: function ($$v) {
              _vm.dialogWonLost = $$v
            },
            expression: "dialogWonLost",
          },
        },
        [
          _vm.dialogWonLost
            ? _c("WonLost", {
                ref: "wonLost",
                staticClass: "mr-2",
                attrs: { element: _vm.stateParams.data },
                on: {
                  onSave: _vm.onSaveWonLost,
                  onDialogClosed: function ($event) {
                    _vm.dialogWonLost = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }