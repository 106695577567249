var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "auto", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-1",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogWordButton = true
                        },
                      },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" mdi-file-word-outline ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialogWordButton,
        callback: function ($$v) {
          _vm.dialogWordButton = $$v
        },
        expression: "dialogWordButton",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("span", [_vm._v("Angebotsgenerierung ")]),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "ml-3 py-0",
                  on: {
                    click: function ($event) {
                      return _vm.closeWordDialog()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-5 pb-0" },
            [
              _vm._v(
                " Bitte geben Sie die Anzahl der Leistungsmodule für das Angebot ein: "
              ),
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.isFormValid,
                    callback: function ($$v) {
                      _vm.isFormValid = $$v
                    },
                    expression: "isFormValid",
                  },
                },
                [
                  _c("v-text-field", {
                    ref: "performanceModuleNumber",
                    attrs: {
                      type: "number",
                      min: 1,
                      max: 9,
                      label: "Anzahl der Leistungsmodule *",
                      rules: _vm.ruleOneDigitNumber,
                    },
                    model: {
                      value: _vm.performanceModuleNumber,
                      callback: function ($$v) {
                        _vm.performanceModuleNumber = $$v
                      },
                      expression: "performanceModuleNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeWordDialog()
                    },
                  },
                },
                [_vm._v(" Zurück ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.isFormValid,
                    color: "blue darken-1",
                    outlined: "",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.generateDocument(_vm.element.id)
                    },
                  },
                },
                [_vm._v("Angebot generieren")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }