<template>
  <v-card elevation="0">
    <v-card-title>
      <v-row>
        Dienstleistungen
        <div>
          <v-icon color="primary"> mdi-information-outline </v-icon>
          <v-icon v-show="!alert" @click="alert = !alert">
            mdi-arrow-down-drop-circle-outline
          </v-icon>
          <v-icon v-show="alert" @click="alert = !alert">
            mdi-arrow-up-drop-circle-outline
          </v-icon>

          <v-alert :value="alert" border="top" colored-border type="info" elevation="1">
            <div class="grid-container">
              <div class="grid-item-header-skillname">Dienstleistung</div>
              <div class="grid-item-header">bis 40 PT</div>
              <div class="grid-item-header">41 bis 160 PT</div>
              <div class="grid-item-header">mehr als 160 PT</div>
            </div>

            <div v-for="skill in skills" :key="skill.id">
              <div class="grid-container">
                <div class="grid-item-skillname">
                  {{ skill.name }}
                </div>
                <div class="grid-item">
                  {{ skill.lessThan40PT | formatNumber("0,0.00 $ ") }}
                </div>
                <div class="grid-item">
                  {{ skill.between41To160PT | formatNumber("0,0.00 $ ") }}
                </div>
                <div class="grid-item">
                  {{ skill.moreThan160PT | formatNumber("0,0.00 $ ") }}
                </div>
              </div>
            </div>
          </v-alert>
        </div>
        <v-spacer></v-spacer>
        <v-col md="6" sm="6">
          <sx-currency-input :disabled="true" :readonly="true" v-model="totalSum" label="Gesamtsumme"
            data-cy="skills-totalsum" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-20">
      <v-row>
        <v-form class="mt-3" ref="serviceInformation" style="width: 100%" v-show="!isEditing">
          <v-row>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <v-autocomplete :disabled="isEditing" :items="$store.getters['skill/getSkillsName']"
                v-model="editServiceItem.skillName" :rules="[(v) => !!v || 'Feld darf nicht leer sein.']"
                @input="() => updatePrices(true)" label="Qualifikation *" data-cy="skill-name" outlined dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2" md="2" class="py-0 skill-col">
              <v-text-field type="number" v-model="days" @input="() => updatePrices(false)" suffix="PT"
                :disabled="isEditing" label="Tage *" :rules="[
                  (v) => /^\d+$/.test(v) || 'Es sind nur Zahlen erlaubt.',
                ]" data-cy="skill-days" outlined single-line dense></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-0">
              <sx-currency-input :disabled="isEditing" v-model="editServiceItem.pricePerDay"
                @change="() => updatePrices(false)" @click:clear="clear()" label="Tagessatz" />
            </v-col>

            <v-col cols="12" sm="3" md="3" class="py-0">
              <sx-currency-input :disabled="isEditing" :rules="ruleRequiredText" v-model="editServiceItem.sum"
                @change="sumChanged" label="Summe" />
            </v-col>

            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3" class="text-right mr-3 mb-5">
                <v-btn height="2.5rem" color="blue darken-1" text @click="addServiceItem" data-cy="add-skill-btn"
                  outlined>Hinzufügen</v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-form>

        <v-dialog v-model="deleteDialogVisible" max-width="500px">
          <v-card>
            <v-card-title class="title">
              Soll das Element gelöscht werden?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="onCloseConfirmDeleteDialog">Zurück</v-btn>
              <v-btn color="blue darken-1" text @click="onDeleteServiceConfirmed">JA</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col cols="12" sm="12" md="12" class="px-0">
          <v-data-table :loading="isLoading" loading-text="Dienstleistungen werden geladen..." :headers="[
            {
              text: 'Qualifikation',
              align: 'start',
              sortable: false,
              value: 'skillName',
            },
            { text: 'Tage', value: 'days' },
            { text: 'Tagessatz', value: 'pricePerDay' },
            { text: 'Summe', value: 'sum' },
            //{ text: 'SKU', value: 'skuName' },
            {
              text: 'Aktionen',
              value: 'actions',
              sortable: false,
            },
          ]" :items="opportunityServices" class="elevation-1">
            <template v-slot:[`item.pricePerDay`]="{ item }">{{
              item.pricePerDay | formatNumber("0,0.00 $ ")
            }}</template>

            <template v-slot:[`item.sum`]="{ item }">
              {{ item.sum | formatNumber("0,0.00 $ ") }}
            </template>

            <!--<template v-slot:[`item.skuName`]="{ item }">{{
              item.skuName
            }}</template>-->

            <template v-slot:[`item.actions`]="{ item }" v-if="!isEditing">
              <v-dialog v-model="editServiceDialogVisible" max-width="400px">
                <v-card>
                  <v-card-title>Dienstleistung</v-card-title>
                  <v-card-text>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete :items="$store.getters['skill/getSkillsName']" v-model="editServiceItem.skillName"
                        label="Dienstleistungen" outlined dense></v-autocomplete>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="editServiceDialogVisible = false" outlined>Zurück</v-btn>
                    <v-btn color="blue darken-1" text @click="addServiceItem" outlined>Speichern</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-icon small @click="showConfirmDeleteDialog(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn outlined disabled>
                <span>Keine Dienstleistungen *</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <sx-dialog title="Warnung" ref="warning">
        <v-alert outlined type="error" prominent border="left" data-cy="no-skills-warning">
          Es wurde noch keine Dienstleistungen hingefügt! Bitte Dienstleistungen
          hinzufügen!
        </v-alert>
        <template slot="actions">
          <v-spacer />
          <v-btn color="blue darken-1" @click.native="() => $refs.warning.close()" data-cy="close-no-skills-warning"
            outlined>Zurück</v-btn>
        </template>
      </sx-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { oppService } from "@/services";

export default {
  name: "ServiceCard",

  props: {
    opportunity: Object,
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editServiceDialogVisible: false,
      deleteDialogVisible: false,
      opportunityServices: [],
      servicesModified: false,
      serviceToDelete: null,
      alert: false,
      cleared: false,
      noTravelcost: false,
      editServiceItem: {
        skillName: null,
        pricePerDay: null,
        days: null,
        sum: null,
      },
      defaultItem: {
        skillName: null,
        pricePerDay: null,
        days: null,
        sum: null,
      },
      isLoading: false,

      // Rule that checks if a field contains any input.
      ruleRequiredText: [
        (value) => !!value || "Das Feld darf nicht leer sein.",
      ],
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.loadServices();
  },

  watch: {
    // watch for outside changes on the opportunity object
    // to reload the entire state
    opportunity: async function () {
      await this.loadServices();
    },

    opportunityServices(services) {
      let found = services.filter((skill) => skill.skillName === "Reisekosten");
      if (found.length === 0) this.noTravelcost = true;
      else this.noTravelcost = false;

      this.travel(this.noTravelcost);
    },
  },

  computed: {
    ...mapGetters("oppService", ["getItemServices"]),

    skills() {
      let tmp = this.$store.getters["skill/skills"];
      return tmp;
    },

    getSkillName() {
      const skills = this.$store.getters["skill/skills"];
      const skill = skills.find(skill => skill.name === this.editServiceItem.skillName);
      if (!skill) return "";
      const days = this.editServiceItem.days;
      if (days < 41) return skill.lessThan40PTName;
      if (days <= 160) return skill.between41To160PTName;
      if (days > 160) return skill.moreThan160PTName;
      return "";
    },

    days: {
      get() {
        return this.editServiceItem.days;
      },
      set(value) {
        if (value == null) this.editServiceItem.days = 1;
        else this.editServiceItem.days = parseInt(value);
        this.editServiceItem.pricePerDay = this.$store.getters[
          "skill/getPrice"
        ](this.editServiceItem);
      },
    },

    // calculate the total sum of all services in this opportunity
    totalSum: function () {
      let totalSum = 0;
      this.opportunityServices.forEach((service) => {
        totalSum += service.sum;
      });
      // notify parent component about changes to the total cost of this opportunity
      this.$emit("adoptTotalCost", totalSum);
      return totalSum;
    },
  },

  methods: {
    travel(value) {
      this.$emit("travel", value);
    },
    clear() {
      this.cleared = true;
    },

    /**
     * Load all services (collection of skills, days and cost) for this opportunity id
     */
    async loadServices() {

      // try to get the services for this opportunity
      let serviceObject = await oppService.getByOpportunityId(
        this.opportunity.id
      );

      if (serviceObject != '' && serviceObject != null) {
        // get orderedServices and if they're null, default to services or an empty array
        this.opportunityServices =
          serviceObject.orderedServices || serviceObject.services || [];
      }

      this.isLoading = false;
    },

    // Manually trigger a recalculation of all prices
    updatePrices(fullUpdate) {
      if (fullUpdate) {
        this.editServiceItem.days = 1;
        this.editServiceItem.pricePerDay = this.$store.getters[
          "skill/getPrice"
        ](this.editServiceItem);
        this.editServiceItem.sum = this.$store.getters["skill/servicePrice"](
          this.editServiceItem,
          this.editServiceItem.pricePerDay
        );
        return;
      }

      if (this.cleared) {
        this.editServiceItem.pricePerDay = null;
        this.cleared = false;
      }

      if (this.editServiceItem.days > 0) {
        this.editServiceItem.sum = this.$store.getters["skill/servicePrice"](
          this.editServiceItem,
          this.editServiceItem.pricePerDay
        );
      }
    },

    // Add a new service from the form to the collection/table
    addServiceItem() {
      // if the validation state is correct
      if (this.$refs.serviceInformation.validate()) {
        let newServiceItem = Object.assign({}, this.editServiceItem);
        newServiceItem.skuName = this.getSkillName;
        this.opportunityServices.push(newServiceItem);
        this.$refs.serviceInformation.resetValidation();
        this.editServiceItem = Object.assign(
          this.editServiceItem,
          this.defaultItem
        );
      }
    },

    // Ask if a service should be removed from the collection/table
    showConfirmDeleteDialog(item) {
      this.serviceToDelete = item;
      this.deleteDialogVisible = true;
    },

    // Remove a service from the collection/table
    onDeleteServiceConfirmed() {
      this.opportunityServices = this.opportunityServices.filter(
        (service) => service != this.serviceToDelete
      );
      this.onCloseConfirmDeleteDialog();
    },

    // Close the confirm delete dialog and reset the state
    onCloseConfirmDeleteDialog() {
      this.deleteDialogVisible = false;
      this.serviceToDelete = null;
    },

    orderedOrServices(services) {
      if (services.orderedServices) return services.orderedServices;
      return services.services;
    },

    // Notify the user that there are no services assigned to this opportunity
    showNoServicesWarning() {
      if (this.opportunityServices.length < 1) {
        this.$refs.warning.show();
        this.$refs.serviceInformation.validate();
        return false;
      }
      return true;
    },
    async saveChanges(opportunityId) {
      let serviceId = "00000000-0000-0000-0000-000000000000";

      let opportunityServices = {
        // get OppServices-Id
        id: serviceId,

        // get Opportunity-Id of new opportunity
        opportunityID: opportunityId,
        // get Services for this Opportunity
        services: this.opportunityServices,
        // flag to reset
        isCloneOrNewVersion: true,
      };

      await oppService.post(opportunityServices);

    },
    // when the sum changes, adapt price per day
    sumChanged(totalSum) {
      if (this.editServiceItem.days != null)
        this.editServiceItem.pricePerDay = totalSum / this.editServiceItem.days;
    },
  },
};
</script>

<style lang="scss">
.reisekosten {
  display: none;
}

.grid-container {
  display: grid;
  grid-template-columns: auto 10rem 8rem 8rem;
  line-height: 1.2rem !important;
}

.grid-item-header-skillname {
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
}

.grid-item-skillname {
  font-size: 0.82rem;
  font-weight: normal;
  letter-spacing: 0;
  align-items: center;
  text-align: left;
  hyphens: auto;
}

.grid-item-header {
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0;
  align-items: center;
  text-align: right;
}

.grid-item {
  font-size: 0.85rem;
  font-weight: normal;
  letter-spacing: 0;
  align-items: center;
  text-align: right;
}

.service-card {
  overflow-y: scroll !important;
}

.noservices {
  color: red;
}

.skill-col {
  position: relative;
}

.skill-name {
  position: absolute;
  min-height: 22px;
  top: 39px;
  left: 13px;
  font-size: 12px;
}
</style>