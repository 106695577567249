<template>
  <v-card elevation="0" @click.passive="closeCalendar">
    <v-card-title>
      {{ dialogTitle }}
      <v-spacer></v-spacer>
      <span class="isReplaced mr-3">{{ ersetztText }}</span>
      <v-divider vertical></v-divider>
      <v-icon @click="close" class="ml-3 py-0">mdi-close</v-icon>
    </v-card-title>
    <v-card-subtitle class="subtitleText">
      {{ element.version }} - {{ element.customerName }}
    </v-card-subtitle>
    <v-progress-circular v-if="isLoading" indeterminate class="wonlost-loader" color="primary"></v-progress-circular>
    <template v-if="!isLoading">
      <v-card-text style="padding: 0 24px 12px" class="card-content">
        <v-row class="wonlost-body">
          <v-col>
            <States v-model="historyState" :realTotalCost="realTotalCost" :opportunity="element"
              @onWonLostChanged="onWonLostChanged($event)" @onLostComment="onLostComment($event, lostComment)"
              @emitDialogClose="close" />
          </v-col>

          <v-col class="wonlost-scrollable" v-show="!isStatusDialog && state == 0">
            <v-form ref="form" :disabled="!canSetLost">
              <template v-if="state === 0">
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="mt-6 py-0">
                    <v-row>
                      <v-menu min-width="0" v-model="isMenuOrderDate" :close-on-click="false">
                        <template v-slot:activator="{ on }">
                          <v-text-field readonly v-on="on" label="Bestelldatum *" :rules="ruleRequiredText"
                            class="text mt-5" :disabled="getCiscoGroup ? true : false" outlined dense
                            :value="formatDate(wonInformation.orderDate)"></v-text-field>
                        </template>
                        <v-date-picker v-model="wonInformation.orderDate" @input="isMenuStartDate = false" locale="de">
                        </v-date-picker>
                      </v-menu>
                    </v-row>
                    <v-row>
                      <v-text-field label="Kundenbestellnummer *" v-model="wonInformation.customerOrderId"
                        :rules="ruleRequiredText" :disabled="getCiscoGroup ? true : false" outlined dense>
                      </v-text-field>
                    </v-row>
                    <v-row>
                      <sx-currency-input :rules="ruleRequiredText" v-model="realTotalCost" :disabled="true"
                        label="Gesamtsumme" />
                    </v-row>
                    <v-row>
                      <small>* Pflichtfelder</small>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider class="mt-10"></v-divider>
                <v-row>
                  <v-card-title class="pl-0">Dienstleistungen</v-card-title>
                </v-row>
                <v-row>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr class="br">
                          <th></th>
                          <th class="text-left text-header">Qualifikation</th>
                          <th class="text-left text-header">Tage</th>
                          <th class="text-left text-header">Preis pro Tag</th>
                          <th class="text-left text-header">Summe in €</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="services != null">
                          <template v-for="(n, index) in services.services.length">
                            <tr class="no-border no-hover" :key="'service_' + index">
                              <td>genehmigt:</td>
                              <td>{{ services.services[index].skillName }}</td>
                              <td>{{ services.services[index].days }}</td>
                              <td>
                                {{
                                  services.services[index].pricePerDay
                                  | formatNumber("0,0.00 $")
                                }}
                              </td>
                              <td>
                                {{
                                  services.services[index].sum
                                  | formatNumber("0,0.00 $")
                                }}
                              </td>
                              <td></td>
                            </tr>

                            <!-------- Workflow orderedServices --------- 
                              - If there is any orderedServices. Copy the services into orderedServices
                              - Default: Display the day, price per day = 0 and total sum 
                            -->
                            <tr :key="'ordered_service_' + index" class="no-hover">
                              <td>beauftragt:</td>
                              <td>
                                {{ services.orderedServices[index].skillName }}
                              </td>
                              <td>
                                <v-text-field :key="index" v-model="services.orderedServices[index].days" @input="
                                  () =>
                                    updatePrices(
                                      services.orderedServices[index]
                                    )
                                " :disabled="getCiscoGroup ? true : false" dense></v-text-field>
                              </td>
                              <td>
                                <sx-currency-input class="currency-input" :key="index" v-model="
                                  services.orderedServices[index].pricePerDay
                                " :disabled="getCiscoGroup ? true : false" :clearable="
  getComputacenterGroup ? true : false
" />
                              </td>
                              <td>
                                <sx-currency-input class="currency-input" :key="index"
                                  v-model="services.orderedServices[index].sum" @change="
                                    sumInput(services.orderedServices[index])
                                  " :disabled="getCiscoGroup ? true : false" :clearable="
  getComputacenterGroup ? true : false
" />
                              </td>
                              <td style="text-align: center" :class="
                                isSumUnequal(
                                  services.orderedServices[index]
                                ) && !isSumInput
                                  ? 'mark-red'
                                  : ''
                              ">
                                <template v-if="getComputacenterGroup ? true : false">
                                  <span>
                                    {{
                                      calculateTotalSum(
                                        services.orderedServices[index]
                                      ) | formatNumber("0,0.00 $")
                                    }}</span>
                                  <div style="display: flex">
                                    <v-icon class="left-arrow" @click="applyCalculatedTotalSum(index)">
                                      mdi-arrow-left-bold</v-icon>
                                    <span>übernehmen</span>
                                  </div>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </template>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="wonlost-actions" v-if="!isStatusDialog || historyState">
        <p v-show="isThresholdExceeded && state == 0" class="threshold">
          <v-icon color="red">mdi-alert</v-icon> Die berichtete Summe übersteigt
          die genehmigte Summe von
          {{ opportunity.totalCost | formatNumber("0,0.00 $") }} um mehr als
          10%.<br />
          Nach Rahmenvertrag muss eine neue Version der Opportunity mit den
          aktuellen Werten erstellt werden.
        </p>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Zurück</v-btn>
        <v-btn color="blue darken-1" text @click="openDialogSendRequest" :disabled="isThresholdExceeded"
          v-show="canSetLost">Speichern
        </v-btn>

        <template>
          <div class="text-center">
            <v-dialog v-if="dialogSendRequest" v-model="dialogSendRequest" width="500">
              <v-card>
                <v-card-title class="title sx-vertical-layout" style="color: red">
                  <v-icon class="icon-center" color="red">mdi-alert-outline</v-icon>
                  Wollen Sie die Änderungen speichern?
                </v-card-title>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogSendRequest = false">Zurück</v-btn>
                  <v-btn color="primary" text @click="save">Ja</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { oppService, opportunityService } from "@/services";
import States from "@/components/content/States.vue";
import { HISTORY_STATE, stateMaxOrderNumber } from "../../helper/historyStates";

export default {
  components: { States },
  name: "WonLost",

  props: ["element"],

  data() {
    return {
      isLoading: true,
      services: null,
      opportunity: null,
      opportunityId: null,
      opportunityCopy: null, // a copy for the <States/> display
      historyState: {},
      HISTORY_STATE: HISTORY_STATE,
      isSumInput: false,
      // 1 - Lost, 0 - Won
      state: 1,
      isMenuOrderDate: false,
      dialogSendRequest: false,

      wonInformation: {
        orderDate: "",
        customerOrderId: null,
        realTotalCost: null,
      },

      // Rule that checks if a field contains any input.
      ruleRequiredText: [
        (value) => !!value || "Das Feld darf nicht leer sein.",
      ],
    };
  },

  computed: {
    ...mapGetters("opportunity", ["states"]),
    ...mapGetters("user", [
      "getComputacenterGroup",
      "getCiscoGroup",
      "getCiscoSibeGroup",
      "getAdminGroup",
    ]),

    dialogTitle: {
      get() {
        if (this.isStatusDialog) return "Status";
        return "Gewonnen/Verloren";
      },
    },

    canSetLost() {
      if (this.getCiscoGroup) return false;
      if (this.getCiscoSibeGroup) return false;
      if (this.isReplaced) return false;

      if (this.getComputacenterGroup) return true;

      return false;
    },

    ersetztText: {
      get() {
        let ersetzt = this.getHistory(HISTORY_STATE.Ersetzt);
        if (ersetzt == null) return "";
        return `ersetzt am ${this.formatDate(ersetzt.modifiedAt)}`;
      },
    },

    isReplaced() {
      return this.opportunity.history.find(
        (item) => item.state.orderNumber == HISTORY_STATE.Ersetzt
      )
        ? true
        : false;
    },

    lostComment: {
      get() {
        let lost = this.getHistory(HISTORY_STATE.Verloren);
        if (lost == null) return "";
        return lost.comment;
      },
      set(v) {
        let oldLost = this.getHistory(HISTORY_STATE.Verloren);
        if (oldLost != null) {
          oldLost.comment = v;
          return;
        }
        if (this.historyState.state.orderNumber == HISTORY_STATE.Verloren)
          this.historyState.comment = v;
      },
    },

    /**
     * Display the status dialog (without WonLost input boxes on the right)
     * if the history state is below "Freigegeben" (orderNumber = 5)
     * and if it's not a replaced opportunity
     */
    isStatusDialog: {
      get() {
        // delay evaluation if opportunity is still loading
        if (this.opportunity == null) return true;
        if (this.opportunity.history == null) return true;
        let maxOrderNumber = stateMaxOrderNumber(this.opportunity);

        return (
          maxOrderNumber < HISTORY_STATE.Freigegeben ||
          maxOrderNumber == HISTORY_STATE.Ersetzt
        );
      },
    },

    hasWonOrLostInHistory() {
      let won = this.getHistory(HISTORY_STATE.Gewonnen);
      let lost = this.getHistory(HISTORY_STATE.Verloren);

      // if the opp has won or lost
      if (won != null) return true;
      if (lost != null) return false;

      // if the user manually clicked on won or lost
      if (this.state == 0) return true;

      return false;
    },

    realTotalCost: {
      get() {
        if (this.services.orderedServices == null) return 0;
        let totalCost = 0;
        this.services.orderedServices.forEach((service) => {
          totalCost += service.sum;
        });
        return totalCost;
      },
      set(value) {
        this.wonInformation.realTotalCost = value;
      },
    },

    wonOrLostOrderDate: {
      get() {
        if (this.wonInformation.orderDate == null) return null;
        try {
          return new Date(this.wonInformation.orderDate)
            .toISOString()
            .split("T")[0];
        } catch (error) {
          return null;
        }
      },
      set(value) {
        this.wonInformation.orderDate = value;
      },
    },

    isThresholdExceeded() {
      return this.realTotalCost > this.opportunity.totalCost * 1.1;
    },
  },

  async mounted() {
    await this.onOpportunityChanged();
  },

  watch: {
    async element() {
      await this.onOpportunityChanged();
    },
  },

  methods: {
    ...mapMutations("oppAndService", ["setOrderedServicesForExcel"]),

    async onOpportunityChanged() {
      this.isLoading = true;
      //this.newHistoryStates = [];

      await oppService.getAll();
      await opportunityService.getAll();

      this.opportunityId = this.element.id;

      // deep copy opportunity and services to prevent side effects
      this.opportunity = JSON.parse(
        JSON.stringify(
          this.$store.state.opportunity.opportunities.find(
            (i) => i.id == this.opportunityId
          )
        )
      );

      this.opportunityCopy = JSON.parse(JSON.stringify(this.opportunity));

      // attempt to load services for this opportunity, if null - default to empty object
      let services =
        this.$store.state.oppService.oppServices.find(
          (i) => i.opportunityID == this.opportunityId
        ) || {};

      this.services = JSON.parse(JSON.stringify(services));

      if (this.services.orderedServices == null)
        this.services.orderedServices = JSON.parse(
          JSON.stringify(this.services.services || [])
        );

      // TODO: WonOrLostState is deprecated

      let won = this.getHistory(HISTORY_STATE.Gewonnen);
      let lost = this.getHistory(HISTORY_STATE.Verloren);

      if (won != null) {
        this.state = 0;
      } else if (lost != null) {
        this.state = 1;
      }

      // Default wonOrLostState object or set opportuntiy.wonOrLostStateObject and
      // Set state won or lost
      if (this.opportunity.state === "Neu") {
        if (this.opportunity.wonInformation) {
          this.wonInformation = this.opportunity.wonInformation;
        }
      } else {
        if (this.opportunity.wonInformation) {
          this.wonInformation = this.opportunity.wonInformation;
        } else {
          this.wonInformation.realTotalCost = this.opportunity.totalCost;
        }
      }

      this.isLoading = false;
    },

    /**
     * Get an entry of the history array by orderNumber,
     * usage:
     * getHistory(HISTORY_STATE.Neu)
     */
    getHistory(historyState) {
      if (this.opportunity == null) return null;
      if (this.opportunity.history == null) return null;
      return this.opportunity.history.find(
        (h) => h.state.orderNumber == historyState
      );
    },

    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    onWonLostChanged(event) {
      this.state = event;
    },

    onLostComment(lostComment) {
      this.state = 1;
      this.lostComment = lostComment;
    },

    isSumUnequal(service) {
      let totalSum = this.calculateTotalSum(service);
      return totalSum != service.sum;
    },

    close() {
      this.$emit("onDialogClosed", false);
    },

    openDialogSendRequest() {
      if (this.getComputacenterGroup) {
        // Won
        if (this.state === 0) {
          if (this.$refs.form.validate()) {
            this.dialogSendRequest = true;
          }
        }
        // Lost
        else {
          this.wonInformation.customerOrderId = null;
          this.wonInformation.orderDate = "";
          this.dialogSendRequest = true;
        }
      } else if (this.getCiscoGroup) {
        this.dialogSendRequest = true;
      }
    },

    closeCalendar() {
      this.isMenuOrderDate = false;
    },

    // Format the date from an ISO string to common german format.
    formatDate(date) {
      if (!date) return null;

      const datePart = date.split("T")[0];
      const [year, month, day] = datePart.split("-");

      return `${day}.${month}.${year}`;
    },

    strToFloat(value) {
      if (value === "") return 0;

      const parseResult = parseFloat(value);

      return isNaN(parseResult) ? 0 : parseResult;
    },

    getPayload() {
      return {
        orderDate: this.wonInformation.orderDate,
        customerOrderId: this.wonInformation.customerOrderId,
        realTotalCost: this.wonInformation.realTotalCost,
      };
    },

    async save() {
      // save all  history record changes
      await opportunityService.patchHistoryRecord(
        this.opportunity.id,
        this.historyState
      );

      if (this.getComputacenterGroup) {
        let payloadOpportunityService = this.services;

        // 0 is won
        if (this.state === 0) {
          // Assign the realTotalCost to totalCost
          this.opportunity.totalCost = this.wonInformation.realTotalCost;
          this.opportunity.wonInformation = this.wonInformation;

          if (payloadOpportunityService.orderedServices) {
            // Update opportunityServices orderedServices
            await oppService.setOrderedServices(payloadOpportunityService.opportunityID, payloadOpportunityService.orderedServices)
          }


          // Set new wonInformation object
          const payload = this.getPayload();

          await opportunityService.setWonInformation(
            this.opportunity.id,
            payload
          );

          this.setOrderedServicesForExcel({
            opportunity: this.opportunity,
            services: this.services,
          });
        } else {
          this.opportunity.wonInformation = this.wonInformation;
          // Opportunity is lost, remove orderedServices
          await oppService.cleanOrderedServices(this.opportunity.id);
        }
      }

      this.dialogSendRequest = false;
      this.$emit("onSave");
      this.close();
    },

    // Calculate the new total sum of this service
    calculateTotalSum(service) {
      return service.pricePerDay * service.days;
    },

    // Take the calculated total sum and overwrite the sum
    // of that service with the new value
    applyCalculatedTotalSum(index) {
      this.services.orderedServices[index].sum = this.calculateTotalSum(
        this.services.orderedServices[index]
      );
    },

    // Manually trigger a recalculation of all prices
    updatePrices(service) {
      this.isSumInput = false;
      service.pricePerDay = this.$store.getters["skill/getPrice"](service);

      if (this.cleared) {
        service.pricePerDay = 0;
        this.cleared = false;
      }
    },

    sumInput(service) {
      service.pricePerDay = 0;
      this.isSumInput = true;
    },
  },
};
</script>

<style lang="sass">
.card-content
  height: auto !important
  max-height: 80vh !important
  position: relative
  overflow-y: auto !important
.wonlost-scrollable
  min-width: 55%
  height: 73vh !important
  overflow-y: auto !important
  padding-left: 25px !important
  border-left: 1px solid #c9c9c9

.wonlost-body
  border-top: 1px solid #c9c9c9

.threshold
  color: red
  font-weight: bold
.serviceheader
  font-weight: bold

.radiobtn
  margin-left: 15rem

.no-border > *
  border: none !important
.no-hover:hover
  background-color: transparent !important

.wonLostBtn
  float: right
.currency-input
  margin-left: -12px !important
  margin-top: 7px !important
  min-width: 130px
  * > *
    border: none !important
  * > *.v-input__slot
    border-bottom: 1px solid #9c9999 !important
    border-radius: 0px
    min-height: 30px !important
  * > *.v-input__append-inner
    margin-top: 3px !important

.left-arrow:hover
  color: blue

.subtitleText
  font-weight: bold
  padding: 0 24px 12px !important

.wonlost-actions
  border-top: 1px solid #c9c9c9

.mark-red, .mark-red > div > button:before
  color: #ee6666

.service-col
  position: relative

.service-skill-name, .service-skill-name-edit
  font-size: 12px
  position: absolute
  top: 32px
  left: 11px
.service-skill-name-edit
  top: 42px
  color: #ee6666

.wonlost-loader
  width: 100% !important
  height: 75px !important
  margin-top: 5% !important
  margin-bottom: 5% !important
</style>