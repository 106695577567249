<template>
  <div>
    <v-row class="opportunity-table-toolbar">
      <v-col cols="8" md="3" sm="3">
        <v-text-field id="filter-text-box" placeholder="Suche..." @input="searchOpportunity()" append-icon="mdi-magnify"
          v-model="searchText" dense clearable outlined></v-text-field>
      </v-col>

      <v-spacer></v-spacer>

      <div class="opportunity-filters">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark outlined v-bind="attrs" v-on="on" @click="() => toggleOpportunityFilter('meine')" :class="{
              'filter-toggled': isOpportunityFilterActive('meine'),
              'filter-hidden': ($store.getters['user/getCiscoGroup'] || $store.getters['user/getCiscoSibeGroup']),
            }">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("meine")
            ? "Alle Opportunities anzeigen"
            : "Nur meine Opportunities zeigen"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'neu-button': true,
              'filter-toggled': isOpportunityFilterActive('neu'),
            }" @click="() => toggleOpportunityFilter('neu')" dark outlined v-bind="attrs" v-on="on">
              Neu
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("neu")
            ? "Alle Opportunities anzeigen"
            : "Nur neue Opportunities zeigen"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'qualifiziert-button': true,
              'filter-toggled': isOpportunityFilterActive('qualifiziert'),
            }" @click="() => toggleOpportunityFilter('qualifiziert')" dark outlined v-bind="attrs" v-on="on">
              Qual
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("qualifiziert")
            ? "Alle Opportunities anzeigen"
            : "Nur qualifizierte Opportunities zeigen"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'abgelehnt-button': true,
              'filter-toggled': isOpportunityFilterActive('abgelehnt'),
            }" @click="() => toggleOpportunityFilter('abgelehnt')" dark outlined v-bind="attrs" v-on="on">
              Abg
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("abgelehnt")
            ? "Alle Opportunities anzeigen"
            : "Nur abgelehnte Opportunities zeigen"
          }}
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'bitte-button': true,
              'filter-toggled': isOpportunityFilterActive('bitteumfreigabe'),
            }" @click="() => toggleOpportunityFilter('bitteumfreigabe')" dark outlined v-bind="attrs" v-on="on">
              Bitte
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("bitteumfreigabe")
            ? "Alle Opportunities anzeigen"
            : 'Nur Opportunities mit "Bitte um Freigabe" zeigen'
          }}
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'frei-button': true,
              'filter-toggled': isOpportunityFilterActive('freigegeben'),
            }" @click="() => toggleOpportunityFilter('freigegeben')" dark outlined v-bind="attrs" v-on="on">
              Frei
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("freigegeben")
            ? "Alle Opportunities anzeigen"
            : "Nur freigegebene Opportunities zeigen"
          }}
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'gewonnen-button': true,
              'filter-toggled': isOpportunityFilterActive('gewonnen'),
            }" @click="() => toggleOpportunityFilter('gewonnen')" dark outlined v-bind="attrs" v-on="on">
              Gew
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("gewonnen")
            ? "Gewonnen-Filter aufheben"
            : "Nur gewonnene Opportunities zeigen"
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'verloren-button': true,
              'filter-toggled': isOpportunityFilterActive('verloren'),
            }" @click="() => toggleOpportunityFilter('verloren')" dark outlined v-bind="attrs" v-on="on">
              Verl
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("verloren")
            ? "Verloren-Filter aufheben"
            : "Nur verlorene Opportunities zeigen"
          }}
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :class="{
              'ersetzt-button': true,
              'filter-toggled': isOpportunityFilterActive('ersetzt'),
            }" @click="() => toggleOpportunityFilter('ersetzt')" dark outlined v-bind="attrs" v-on="on">
              !Ers
            </v-btn>
          </template>
          {{
            isOpportunityFilterActive("ersetzt")
            ? "Verloren-Filter aufheben"
            : "Nur ersetzte Opportunities zeigen"
          }}
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="() => toggleOpportunityFilter('reset')" dark outlined v-bind="attrs" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          Filter zurücksetzen
        </v-tooltip>
      </div>

      <div class="new-opportunity-button">
        <NewOpportunity ref="newopportunity" />
      </div>
    </v-row>
    <div class="mt-3">
      <ag-grid-vue ref="opportunitiesTable" class="ag-theme-alpine opptable" :columnDefs="columnDefs"
        :rowData="oppAndServices" :gridOptions="gridOptions" :defaultColDef="defaultColDef" :enableCellChangeFlash="true"
        :excelStyles="excelStyles" :localeText="localeText" :icons="icons" :masterDetail="true" @grid-ready="onGridReady"
        :loadingOverlayComponentFramework="loadingOverlayComponent" :defaultExcelExportParams="defaultExcelExportParams"
        :detailCellRendererParams="detailCellRendererParams">
      </ag-grid-vue>
      <v-row class="ml-1 mt-5 mr-1 mb-2">
        <EmailDialog ref="emaildialog" />
        <div class="ml-3">
          <ErrorReport />
        </div>
        <v-spacer></v-spacer>
        <v-btn @click="excelDownload">
          <v-icon color="green">mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-row>
    </div>

    <v-dialog v-model="dialogWonLost" content-class="overflow-hidden history-dialog" persistent>
      <WonLost class="mr-2" v-if="dialogWonLost" :element="stateParams.data" @onSave="onSaveWonLost"
        @onDialogClosed="dialogWonLost = $event" ref="wonLost" />
    </v-dialog>

  </div>
</template>

<script>
import { opportunityState, skillService } from "@/services";
import NewOpportunity from "@/components/content/NewOpportunity";
import EditOpportunity from "@/components/content/EditOpportunity";
import States from "@/components/content/States.vue";
import WonLost from "@/components/content/WonLost.vue";
import agGridLocaleDe from "../../config/ag-grid-locale.de.config";
import ServiceCard from "./ServiceCard.vue";
import SxLoadingCellRenderer from "../core/SxLoadingCellRenderer";
import { formatDate } from "../../helper/date.helper";
import EmailDialog from "@/components/content/EmailDialog";
import ErrorReport from "@/components/content/ErrorReport";

// AG-Grid: Master detail excel cell
window.cell = function cell(text, styleId) {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text) ? "Number" : "String",
      value: String(text),
    },
  };
};

export default {
  name: "OpportunitiesTable",

  components: {
    /* eslint-disable vue/no-unused-components */
    EditOpportunity,
    NewOpportunity,
    States,
    WonLost,
    ServiceCard,
    SxLoadingCellRenderer,
    EmailDialog,
    ErrorReport,
  },

  beforeMount() {
    (this.gridOptions = {
      columnDefs: [
        {
          headerName: "Bearbeitet-Datum",
          field: "modifiedAt",
        },
        { headerName: "Erstellt-Name", field: "createdBy" },
        {
          headerName: "Opportunity Name",
          field: "name",
        },
        { headerName: "Kunde", field: "customerName" },
        { headerName: "Status", field: "state" },
        {
          headerName: "Status Beschreibung",
          field: "description",
        },
        { headerName: "qual. Summe", field: "totalCost" },
        {
          headerName: "Aktionen",
          field: "actions",
          suppressMenu: true,
          cellRendererFramework: `EditOpportunity`,
        },
      ],
      accentedSort: true,
      pagination: true,
      paginationPageSize: 10,
    }),
      (this.localeText = agGridLocaleDe);

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      filterParams: { newRowsAction: "keep" },
      menuTabs: ["filterMenuTab"],
      icons: {
        menu: '<i class="mdi mdi-filter"/>',
      },
    };

    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          { field: "skillName" },
          { field: "days" },
          { field: "price" },
        ],
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.services);
      },
    };

    this.skillStyles = this.excelStyles = [
      {
        id: "coverHeading",
        font: {
          size: 15,
          bold: true,
        },
      },
      {
        id: "row",
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      // BEGIN - Skills styles
      {
        id: "skill_1",
        interior: {
          color: "#ffe5cc",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_2",
        interior: {
          color: "#ffcce4",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_3",
        interior: {
          color: "#fff6cc",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_4",
        interior: {
          color: "#ffccff",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_5",
        interior: {
          color: "#fffbcc",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_6",
        interior: {
          color: "#ddccff",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_7",
        interior: {
          color: "#f0ffcc",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_8",
        interior: {
          color: "#ccd8ff",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#ffb04f",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_9",
        interior: {
          color: "#ccffe5",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      {
        id: "skill_10",
        interior: {
          color: "#affa9e",
          pattern: "Solid",
        },
        borders: {
          borderBottom: {
            color: "#000000",
            lineStyle: "Continuous",
            weight: 3,
          },
        },
      },
      // END - Skills styles
      {
        id: "header",
        interior: {
          color: "#D3D3D3",
          pattern: "Solid",
        },
        font: {
          size: 11,
          bold: true,
        },
        borders: {
          borderLeft: {
            color: "#ffffff",
            lineStyle: "Continuous",
            weight: 1,
          },
        },
      },
    ];
  },

  data: () => ({
    isInitialMount: true,
    stateStyleMap: {
      default: { color: "grey", cursor: "pointer" },
      Abgelehnt: { color: "red", cursor: "pointer" },
      Qualifiziert: { color: "#bccc4c", cursor: "pointer" },
      "Bitte um Freigabe": { color: "DeepSkyBlue", cursor: "pointer" },
      Freigegeben: { color: "RoyalBlue", cursor: "pointer" },
      Gewonnen: { color: "green", cursor: "pointer" },
      Verloren: { color: "red", cursor: "pointer" },
    },
    columnWidthMapping: {
      id: 50,
      version: 50,
      customerName: 180,
    },

    searchText: "",
    columnDefs: null,
    defaultColDef: null,
    excelStyles: null,
    showLost: false,
    valid: true,
    icons: null,
    localeText: agGridLocaleDe,
    gridApi: null,
    gridColumnApi: null,
    gridOptions: null,
    loadingOverlayComponent: "SxLoadingCellRenderer",
    detailCellRendererParams: null,
    defaultExcelExportParams: null,
    dialogState: false,
    dialogWonLost: false,
    lastStateOrderNumber: 0,
    stateParams: {},
    isQualified: false,
    defaultFilterParams: {
      buttons: ["clear"],
    },
    oppAndServicesCopy: [],
    opportunityFilters: [],

    // Add Dialog button for email information
    dialog: false,
    infos: [
      {
        id: 1,
        name: "Netzwerk 21082",
        nameDisplay: "Netzwerk-RV 21082",
        requestCustomer: "rv_netzwerk_cisco@cisco.com",
        offer: "rv_netzwerk_cisco_angebot@cisco.com",
        approval: "rv_netzwerk_cisco_freigabe@cisco.com",
        orderConformation: "rv_netzwerk_cisco_auftrag@cisco.com"
      },
      {
        id: 2,
        name: "UCC 21587",
        nameDisplay: "UCC-RV 21587",
        requestCustomer: "rv_ucc_cisco@cisco.com",
        offer: "rv_ucc_cisco_angebot@cisco.com",
        approval: "rv_ucc_cisco_freigabe@cisco.com",
        orderConformation: "rv_ucc_cisco_auftrag@cisco.com"
      }
    ],

    // Map to identify which filters should be toggled or untoggled
    opportunityFilterStates: {
      neu: {
        untoggle: [
          "abgelehnt",
          "bitteumfreigabe",
          "freigegeben",
          "ersetzt",
          "qualifiziert",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      qualifiziert: {
        untoggle: [
          "abgelehnt",
          "bitteumfreigabe",
          "freigegeben",
          "ersetzt",
          "neu",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      bitteumfreigabe: {
        untoggle: [
          "abgelehnt",
          "freigegeben",
          "ersetzt",
          "qualifiziert",
          "neu",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      freigegeben: {
        untoggle: [
          "abgelehnt",
          "bitteumfreigabe",
          "ersetzt",
          "qualifiziert",
          "neu",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      abgelehnt: {
        untoggle: [
          "qualifiziert",
          "bitteumfreigabe",
          "freigegeben",
          "ersetzt",
          "neu",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      ersetzt: {
        untoggle: [
          "abgelehnt",
          "bitteumfreigabe",
          "freigegeben",
          "qualifiziert",
          "neu",
          "gewonnen",
          "verloren",
        ],
        toggle: [],
      },
      gewonnen: {
        untoggle: [
          "abgelehnt",
          "neu",
          "bitteumfreigabe",
          "freigegeben",
          "ersetzt",
          "qualifiziert",
          "verloren",
        ],
        toggle: [],
      },
      verloren: {
        untoggle: [
          "abgelehnt",
          "neu",
          "bitteumfreigabe",
          "freigegeben",
          "ersetzt",
          "qualifiziert",
          "gewonnen",
        ],
        toggle: [],
      },
    },
    listenerOpenDialog: false,

    // Excel
    skillFields: [],
    skillStyles: [],
    excelDownloadFields: [
      "version",
      "modifiedAt",
      "createdAt",
      "createdBy",
      "name",
      "customerName",
      "description",
      "state",
      "stateDescription",
      "totalCost",
      "wonInformation.realTotalCost",
      // history
      "states.qualified.modifiedBy",
      "states.qualified.modifiedAt",
      "states.declined.modifiedBy",
      "states.declined.modifiedAt",
      "states.request.modifiedBy",
      "states.request.modifiedAt",
      "states.approved.modifiedBy",
      "states.approved.modifiedAt",
      "approvalId",
      "states.won.modifiedBy",
      "states.won.modifiedAt",
      "wonInformation.orderDate",
      "wonInformation.customerOrderId",
      "states.lost.modifiedBy",
      "states.lost.modifiedAt",
      "states.lost.comment",
      "states.replaced.modifiedBy",
      "states.replaced.modifiedAt",
    ],
  }),

  async mounted() {
    this.isInitialMount = true;

    this.$store.dispatch("oppAndService/getAll");

    // gridOptions
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    opportunityState.getAll();

    await skillService.getAll();
    let skills = this.$store.getters["skill/skills"];

    this.columnDefs = [
      {
        headerName: "Opp-ID",
        width: 120,
        field: "version",
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
        sort: "desc",
        comparator: (valueA, valueB) => {
          return this.strToFloat(valueA) - this.strToFloat(valueB);
        },
        unSortIcon: true,
      },
      {
        headerName: "Bearbeitet-Datum",
        field: "modifiedAt",
        width: 130,
        cellClass: "dateDe",
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
        unSortIcon: true,
        valueFormatter: (params) => {
          return formatDate(params.value);
        },
      },
      {
        headerName: "Erstellt-Name",
        field: "createdBy",
        unSortIcon: true,
        width: 130,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
      },
      {
        headerName: "KAM",
        field: "cC_KAM",
        unSortIcon: true,
        width: 130,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
      },
      {
        headerName: "Opportunity Name",
        field: "name",
        flex: 1,
        unSortIcon: true,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
      },
      {
        headerName: "Kunde",
        field: "customerName",
        flex: 1,
        unSortIcon: true,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
      },
      {
        headerName: "Beschreibung",
        field: "description",
        hide: true,
        unSortIcon: true,
      },
      {
        headerName: "Status",
        field: "state",
        width: 110,
        unSortIcon: true,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,

        cellRenderer: (params) => {
          let state = "";
          if (params.data.history) {
            for (let history of params.data.history) {
              if (history.state) {
                state = history.state.name;
                if (history.state.name === "Ersetzt") {
                  return `<i class="mdi mdi-exclamation-thick replaced">
                                        <span class="tooltiptext">Ersetzt</span>
                                        </i> Ersetzt`;
                }
              }
            }
          }
          return state;
        },

        onCellClicked: (params) => {
          this.stateParams = params;
          this.dialogWonLost = true;
        },

        cellStyle: (params) => {
          let state = "";

          if (params.data.history) {
            for (let history of params.data.history) {
              if (history.state) {
                state = history.state.name;
              }
            }
            if (state in this.stateStyleMap) return this.stateStyleMap[state];
            return this.stateStyleMap["default"];
          }
        },
      },
      {
        headerName: "qual. Summe",
        cellRenderer: (params) => {
          return this.$options.filters.formatNumber(params.value, "0,0.00 $");
        },
        field: "totalCost",
        width: 180,
        unSortIcon: true,
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
        cellStyle: (params) => {
          if (params.column.colId === "totalCost") {
            //mark police cells as red
            return { "text-align": "right" };
          }
          return null;
        },
      },
      {
        headerName: "Aktionen",
        field: "actions",
        suppressMenu: true,
        cellRendererFramework: `EditOpportunity`,
        width: 170,
      },

      // Columns needed for excel export - display: none
      {
        headerName: "ID",
        field: "id",
        width: 5,
        hide: true,
      },
      {
        headerName: "Opportunity Beschreibung",
        field: "description",
        hide: true,
      },
      {
        headerName: "Cisco Kommentar",
        field: "stateDescription",
        hide: true,
      },
      {
        headerName: "Erstellt-Datum",
        field: "createdAt",
        hide: true,
      },

      {
        headerName: "Bearbeitet-Name",
        field: "modifiedBy",
        hide: true,
      },
      {
        headerName: "Start Datum",
        field: "startDate",
        hide: true,
      },
      {
        headerName: "Dauer",
        field: "duration",
        hide: true,
      },
      {
        headerName: "bestellte Summe",
        field: "wonInformation.realTotalCost",
        width: 110,
        hide: true,
      },
      // History
      {
        headerName: "Qualifiziert-Name",
        field: "states.qualified.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Qualifiziert-Datum",
        field: "states.qualified.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Abgelehnt-Name",
        field: "states.declined.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Abgelehnt-Datum",
        field: "states.declined.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Bitte-Name",
        field: "states.request.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Bitte-Datum",
        field: "states.request.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Freigabe-Name",
        field: "states.approved.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Freigabe-Datum",
        field: "states.approved.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Unique-ID",
        field: "approvalId",
        width: 110,
        hide: true,
      },
      {
        headerName: "Gewonnen-Name",
        field: "states.won.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Gewonnen-Datum",
        field: "states.won.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Bestelldatum",
        field: "wonInformation.orderDate",
        width: 130,
        cellClass: "dateDe",
        filter: "agTextColumnFilter",
        filterParams: this.defaultFilterParams,
        unSortIcon: true,
        hide: true,
        valueFormatter: (params) => {
          return formatDate(params.value);
        },
      },
      {
        headerName: "Kundenbestellnummer",
        field: "wonInformation.customerOrderId",
        width: 180,
        hide: true,
      },
      {
        headerName: "Verloren-Name",
        field: "states.lost.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Verloren-Datum",
        field: "states.lost.modifiedAt",
        width: 110,
        hide: true,
      },
      {
        headerName: "Verloren-Kommentar",
        field: "states.lost.comment",
        width: 110,
        hide: true,
      },
      {
        headerName: "Ersetzt-Name",
        field: "states.replaced.modifiedBy",
        width: 110,
        hide: true,
      },
      {
        headerName: "Ersetzt-Datum",
        field: "states.replaced.modifiedAt",
        width: 110,
        hide: true,
      },
    ];

    if (this.skillFields.length == 0) {
      // Dynamically add the skills to the header
      for (let i = 0; i < skills.length; i++) {
        let skillSku, skillPT, skillPrice, skillHeader;
        let searchString = "- ";
        let substringIndex = skills[i].name.search(searchString);
        if (substringIndex !== -1) {
          skillHeader = skills[i].name.substring(0, substringIndex);
        } else {
          skillHeader = skills[i].name;
        }

        skillSku = {
          headerName: `${skillHeader} - SKU`,
          field: `skill_${i}_name`,
          hide: true,
        };
        skillPT = {
          headerName: `${skills[i].name} - PT`,
          field: `skill_${i}_pt`,
          hide: true,
        };
        skillPrice = {
          headerName: `${skills[i].name} - Summe`,
          field: `skill_${i}_price`,
          hide: true,
        };
        // Custom excel fields
        this.skillFields.push(skillSku.field, skillPT.field, skillPrice.field);

        this.columnDefs.push(skillSku, skillPT, skillPrice);
      }
    }

    this.defaultExcelExportParams = {
      getCustomContentBelowRow: (params) => {
        if (params.node.data.services === null) {
          /* eslint-disable */
          // fill in empty cells and with excelStyles "row" in this.excelStyles
          let excelCellsHeader = Array(this.excelDownloadFields.length).fill(
            cell(" ", "row")
          );
          return [excelCellsHeader];
        } else {
          // fill in empty cells and with excelStyles "row" in this.excelStyles
          let excelCellsHeader = Array(this.excelDownloadFields.length).fill(
            cell(" ", "row")
          );

          const skillColumns =
            this.excelDownloadFields.length - this.skillFields.length;
          let startOfSkillsColumn = skillColumns;

          // Loop through the skills and set the column styles
          for (let i = 0; i < skills.length; i++) {
            excelCellsHeader[startOfSkillsColumn++] = cell(
              " ",
              `${this.excelStyles[2 + i].id}`
            );
            excelCellsHeader[startOfSkillsColumn++] = cell(
              " ",
              `${this.excelStyles[2 + i].id}`
            );
            excelCellsHeader[startOfSkillsColumn++] = cell(
              " ",
              `${this.excelStyles[2 + i].id}`
            );
          }

          // --- AG-Grid Excel Export - Fill data in specific cell ---
          // The skills have specific columns in the excel
          // Each skill need two columns/cell, 1. days ; 2. price
          // Search for the skillName and insert into the specific cell
          params.node.data.services.forEach((service) => {
            startOfSkillsColumn = skillColumns;
            for (let i = 0; i < skills.length; i++) {
              // Insert skill into the excel cell
              if (skills[i].name === service.skillName) {
                excelCellsHeader[startOfSkillsColumn++] = cell(
                  service.skuName,
                  `${this.excelStyles[2 + i].id}`
                );
                excelCellsHeader[startOfSkillsColumn++] = cell(
                  service.days,
                  `${this.excelStyles[2 + i].id}`
                );
                excelCellsHeader[startOfSkillsColumn++] = cell(
                  service.sum,
                  `${this.excelStyles[2 + i].id}`
                );
              } else {
                // Preventing merge across
                if (startOfSkillsColumn >= this.excelDownloadFields.length) {
                  startOfSkillsColumn = skillColumns;
                }
                // Move 3 columns to the right
                startOfSkillsColumn = startOfSkillsColumn + 3;
              }
            }
          });

          return [excelCellsHeader];
        }
      },
      columnWidth: 120,
    };
  },

  computed: {
    getAllStates() {
      return this.$store.getters["opportunityState/states"];
    },

    isReplaced() {
      if (!this.stateParams.data.wonOrLostState) {
        return false;
      } else {
        return this.stateParams.data.wonOrLostState.state === "Replaced"
          ? true
          : false;
      }
    },

    oppAndServices: {
      get() {
        // apply opportunity filtering
        // copy the full collection
        this.oppAndServicesCopy =
          this.$store.getters["oppAndService/getOppAndServices"];
        // filter by current user
        if (this.isOpportunityFilterActive("meine")) {
          let name = `${this.$store.state.userInfo.lastname}, ${this.$store.state.userInfo.firstname}`;
          this.oppAndServicesCopy = this.oppAndServicesCopy.filter(
            (o) => o.createdBy == name
          );
        }

        // filter by state if a state filter is active
        if (
          this.isOpportunityFilterActive("gewonnen") ||
          this.isOpportunityFilterActive("verloren") ||
          this.isOpportunityFilterActive("ersetzt") ||
          this.isOpportunityFilterActive("neu") ||
          this.isOpportunityFilterActive("abgelehnt") ||
          this.isOpportunityFilterActive("freigegeben") ||
          this.isOpportunityFilterActive("bitteumfreigabe") ||
          this.isOpportunityFilterActive("qualifiziert")
        )
          this.oppAndServicesCopy = this.oppAndServicesCopy.filter(
            (opportunity) => {
              if (opportunity.history != null && opportunity.history.length) {
                var lastEntry =
                  opportunity.history[opportunity.history.length - 1];

                if (lastEntry != null) {
                  var stateName = lastEntry.state?.name;

                  if (stateName != null) {
                    return this.isOpportunityFilterActive(
                      stateName.toLowerCase().replace(/\s+/g, "")
                    );
                  }

                  return false;
                }
                return false;
              }
              return false;
            }
          );

        return this.oppAndServicesCopy;
      },

      set(updatedOppAndServices) {
        this.oppAndServicesCopy = updatedOppAndServices;
      },
    },
  },

  methods: {
    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    strToFloat(value) {
      if (value === "") {
        return 0;
      } else {
        const parseResult = parseFloat(value);
        return isNaN(parseResult) ? 0 : parseResult;
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.gridApi.showLoadingOverlay();
    },

    isOpportunityFilterActive(filterName) {
      return this.opportunityFilters.includes(filterName);
    },

    removeOpportunityFilter(filterName) {
      this.opportunityFilters = this.opportunityFilters.filter(
        (f) => f != filterName
      );
    },

    toggleOpportunityFilter(filterName) {
      if (filterName == "reset") {
        this.opportunityFilters = this.opportunityFilters.includes("meine")
          ? ["meine"]
          : [];
        return;
      }
      if (this.opportunityFilters.includes(filterName))
        this.removeOpportunityFilter(filterName);
      else this.opportunityFilters.push(filterName);

      if (filterName in this.opportunityFilterStates) {
        this.opportunityFilterStates[filterName].untoggle.forEach((f) =>
          this.removeOpportunityFilter(f)
        );
        this.opportunityFilterStates[filterName].toggle.forEach((f) =>
          this.opportunityFilters.push(f)
        );
      }
    },

    excelDownload() {
      // export without actions column
      let currentDate = formatDate(new Date().toISOString());

      if (this.$store.getters['contract/getContract'].name !== 'UCC 21587') {
        const approvalIdIndex = this.excelDownloadFields.indexOf('approvalId');
        if (approvalIdIndex > -1) {
          // Remove 'approvalId' from the array if it exists
          this.excelDownloadFields.splice(approvalIdIndex, 1);
        }
      } else if (this.$store.getters['contract/getContract'].name === 'UCC 21587' && !this.excelDownloadFields.includes("approvalId")) {
        const approvedModifiedAtIndex = this.excelDownloadFields.indexOf('states.approved.modifiedAt');
        // Add 'approvalId' after 'states.approved.modifiedAt'
        this.excelDownloadFields.splice(approvedModifiedAtIndex + 1, 0, "approvalId");
      }

      let columns = this.excelDownloadFields;

      this.skillFields.forEach((skill) => {
        if (!columns.includes(skill)) {
          columns.push(skill);
        }
      });

      this.gridApi.exportDataAsExcel({
        prependContent: [
          [
            {
              styleId: "coverHeading",
              mergeAcross: 3,
              data: {
                value: `Opportunities Liste - ${currentDate}`,
                type: "String",
              },
            },
          ],
        ],
        columnKeys: columns,

        // date format DE
        processCellCallback: (params) => {
          if (
            ["createdAt", "modifiedAt", "wonInformation.orderDate"].includes(
              params.column.getColId()
            )
          ) {
            return (params.value = formatDate(params.value));
          }

          return params.value;
        },
        // Column width
        columnWidth: (params) => {
          if (params.column !== null) {
            let colId = params.column.getColId();
            if (colId in this.columnWidthMapping) {
              return this.columnWidthMapping[colId];
            }
          }

          return 200;
        },
        fileName: "Opportunities_Liste.xlsx",
        sheetName: "Opportunities",
      });
    },

    searchOpportunity() {
      // Reset the search
      if (this.searchText === null)
        document.getElementById("filter-text-box").value = "";

      this.gridOptions.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    },

    openDialog() {
      if (this.listenerOpenDialog) {
        let manipulatedObject = this.$store.state.clonedOrNewVersionData;
        this.$refs.newopportunity.open(manipulatedObject);
      }
    },

    onSaveWonLost() {
      this.$store.dispatch("oppAndService/getAll");
    },
  },

  watch: {
    //----------------- Listener ----------------------
    // Store watcher on clonedOrNewVersionData.state
    // NewVal assign to this.listenerOpenDialog (data)
    // Call openDialog()
    //-------------------------------------------------
    "$store.state.clonedOrNewVersionData": function (state) {
      this.listenerOpenDialog = state;
      this.openDialog();
    },

    "$store.state.contract.contract": function () {
      this.$store.dispatch("oppAndService/getAll");
    },
    "$store.state.oppAndService.isLoading": function (isLoading) {
      this.gridOptions.suppressLoadingOverlay = !isLoading;
      if (isLoading) this.gridApi.showLoadingOverlay();
    },
    "$store.state.oppAndService.oppAndServices": function () {
      if (
        this.isInitialMount &&
        this.$store.getters["oppAndService/getOppAndServices"].length > 0
      ) {
        this.isInitialMount = false;
        // open opportunity dialog if lowercase id is inside the url
        let q = this.$route.query;
        this.searchText =
          q[Object.keys(q).find((k) => k.toLowerCase() == "opportunityid")];
        if (this.searchText != null)
          if (this.searchText.length > 0) {
            this.$nextTick(() => {
              // apply search and wait until element is rendered
              this.searchOpportunity();
              let interval = setInterval(() => {
                let element = document.getElementById(
                  "opportunity_view_" + this.searchText
                );
                if (element != null) {
                  // open the editor and stop the interval
                  element.click();
                  clearInterval(interval);
                }
              }, 200);
              // stop the interval after 10 seconds by default
              setTimeout(() => {
                clearInterval(interval);
              }, 10000);
            });
          }
        this.gridApi.hideOverlay();
      }
    },
  },
};
</script>

<style lang="scss">
.opptable {
  height: 32.5rem !important;
}

.wonLostWrapper {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
  }
}

.history-dialog {
  width: fit-content !important;
  height: fit-content !important;
  max-height: 100% !important;
}

.replaced {
  color: red;
}

.replaced .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: red;
  text-align: center;
  font-weight: bold;

  /* Position the tooltip */
  position: absolute;
  z-index: 10;
}

.replaced:hover .tooltiptext {
  visibility: visible;
}

.opportunity-filters {
  margin-top: 13px;
  display: flex;
}

.opportunity-filters>button {
  background-color: white;
  font-size: 10px !important;
  border: 1px solid #24387f;
  color: #24387f;
  border-radius: 0px;
  margin-right: -1px;
}

.opportunity-filters>button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.opportunity-filters>button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.opportunity-filters>button>.v-btn__content {
  color: #24387f;
}

.neu-button>.v-btn__content {
  color: grey !important;
}

.qualifiziert-button>.v-btn__content {
  color: #bccc4c !important;
}

.gewonnen-button>.v-btn__content {
  color: green !important;
}

.abgelehnt-button>.v-btn__content,
.verloren-button>.v-btn__content {
  color: red !important;
}

.bitte-button>.v-btn__content {
  color: DeepSkyBlue !important;
}

.freigegeben-button>.v-btn__content {
  color: RoyalBlue !important;
}

.ersetzt-button>.v-btn__content {
  color: grey !important;
}

.filter-toggled {
  background-color: #d9ebff !important;
}

.filter-hidden,
.filter-hidden {
  display: none;
}

.new-opportunity-button {
  margin-top: 12px;
  margin-left: 25px;
  margin-right: 10px;
}

.opportunity-table-toolbar {
  height: 70px;
}
</style>
