<template>
    <ContentContainer>
        <OpportunitiesTable></OpportunitiesTable>

    </ContentContainer>
</template>

<script>
import ContentContainer from "@/components/content/ContentContainer";
import OpportunitiesTable from '@/components/content/OpportunitiesTable.vue';


export default {
    // This component describes the main- or homepage, where the framework agreement data is shown.

    name: "Home",

    metaInfo: { title: "Home" },

    components: {
        ContentContainer,
        OpportunitiesTable,
    },
    methods: {
        hasPermission: function (s) {
            return this.$store.getters.hasPermission(s);
        },
    },
    computed: {
        roles: function () {
            return this.$store.state.userInfo.roles;
        },
    },
};
</script>

<style lang="scss">
tbody td {
    font-size: 0.8rem !important;
}
</style>
