<template>
  <v-dialog v-model="dialog" persistent max-width="45rem" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" small class="mr-1 version" @click="dialogSendRequest = true">VS</span>
      <v-card v-if="dialog">
        <template>
          <div class="text-center">
            <v-dialog v-if="dialogSendRequest" v-model="dialogSendRequest" width="550">
              <v-card>
                <v-card-title class="title">
                  <v-icon class="icon-center" color="red">mdi-alert-outline</v-icon>
                  Wollen Sie eine Opportunity Version erstellen?
                </v-card-title>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close"> Zurück </v-btn>
                  <v-btn color="primary" text @click="save"> Ja </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Versioning",

  props: ["element"],

  data() {
    return {
      dialogSendRequest: false,
      dialog: false,
    };
  },

  methods: {
    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    async save() {
      this.$store.commit("setClonedOrNewVersionData", {
        state: true,
        isNewVersion: true,
        oldOpportunity: this.element,
      });

      this.close();
    },

    close() {
      this.dialogSendRequest = false;
      this.dialog = false;
    },
  },

  computed: {
    ...mapGetters("user", ["getComputacenterGroup"]),
    ...mapGetters("opportunity", ["states"]),
    ...mapGetters("oppService", ["getItemServices"]),
    ...mapGetters("oppAndService", ["getOpportunity"]),
  },
};
</script>

<style>
.version {
  border: 1px solid black;
}

.title {
  color: red;
  word-wrap: break-word;
}
</style>