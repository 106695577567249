<template>
    <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="!$store.getters['contract/isDisabledContract']" v-bind="attrs" v-on="on">
                <v-icon color="blue darken-1">mdi-information-outline</v-icon>Cisco E-Mails
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                <v-icon color="blue darken-1">mdi-information-outline</v-icon>&nbsp;Cisco E-Mails
            </v-card-title>

            <v-card-text class="pt-5 pb-0">
                <div v-for="(info) in infos" :key="info.id">
                    <div v-if="info.name == $store.getters['contract/getContract'].name">
                        <p>Für den {{ info.nameDisplay }} ist in folgenden Fällen Cisco zu informieren oder
                            anzuschreiben:
                        </p>
                        <p>
                            1. Bei Anfragen des Kunden <span v-if="info.name == 'Netzwerk 21082'"> (nur
                                schriftliche)</span><span>: <br></span>
                            <span v-if="info.name !== 'UCC 21587'">Kopie weiterleiten an <b>{{
                                info.requestCustomer
                            }}</b><v-icon small color="blue darken-1"
                                    @click="copyText(info.requestCustomer)">mdi-content-copy</v-icon></span>
                            <span v-if="info.name == 'UCC 21587'">Weiterleiten an <b>{{
                                info.requestCustomer
                            }}</b><v-icon small color="blue darken-1"
                                    @click="copyText(info.requestCustomer)">mdi-content-copy</v-icon> &#10141; Cisco
                                erstellt
                                eine Unique ID und leitet die Anfrage an den richtigen Partner weiter</span>
                        </p>
                        <p>2. Beim Versenden der Angebote: <br>Kopie an <b>{{ info.offer }}</b><v-icon small
                                color="blue darken-1" @click="copyText(info.offer)">mdi-content-copy</v-icon></p>
                        <p>3. Bei Bitte um Freigabe: <br>Mail an <b>{{ info.approval }}</b><v-icon small
                                color="blue darken-1" @click="copyText(info.approval)">mdi-content-copy</v-icon></p>
                        <p>4. Bei Versenden der Auftragsbestätigung: <br>Kopie an <b>{{
                            info.orderConformation
                        }}</b><v-icon small color="blue darken-1"
                                @click="copyText(info.orderConformation)">mdi-content-copy</v-icon></p>
                    </div>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" outlined text @click="dialog = false">
                    Zurück
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "EmailDialog",

    data: () => ({
        // Add Dialog button for email information
        dialog: false,
        infos: [
            {
                id: 1,
                name: "Netzwerk 21082",
                nameDisplay: "Netzwerk-RV 21082",
                requestCustomer: "rv_netzwerk_cisco@cisco.com",
                offer: "rv_netzwerk_cisco_angebot@cisco.com",
                approval: "rv_netzwerk_cisco_freigabe@cisco.com",
                orderConformation: "rv_netzwerk_cisco_auftrag@cisco.com"
            },
            {
                id: 2,
                name: "UCC 21587",
                nameDisplay: "UCC-RV 21587",
                requestCustomer: "rv_ucc_cisco@cisco.com",
                offer: "rv_ucc_cisco_angebot@cisco.com",
                approval: "rv_ucc_cisco_freigabe@cisco.com",
                orderConformation: "rv_ucc_cisco_auftrag@cisco.com"
            }
        ],
    }),

    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    }
}

</script>