var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getComputacenterGroup
    ? _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                disabled: !_vm.$store.getters["contract/isDisabledContract"],
                bottom: "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g({}, on),
                          [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.getComputacenterGroup,
                                    expression: "getComputacenterGroup",
                                  },
                                ],
                                staticClass: "white--text",
                                attrs: {
                                  color: "primary",
                                  "data-cy": "new-opportunity",
                                  disabled:
                                    _vm.$store.getters[
                                      "contract/isDisabledContract"
                                    ],
                                },
                                on: { click: _vm.open },
                              },
                              [_vm._v(" Neue Opportunity ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3427928270
              ),
            },
            [
              _c("span", [
                _vm._v(
                  "Dieser Vertrag ist bereits unterzeichnet, weitere neue Opportunities werden nicht mehr aufgenommen."
                ),
              ]),
            ]
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "55rem", scrollable: "", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _vm.dialog
                ? _c(
                    "v-card",
                    {
                      attrs: { elevation: "0" },
                      on: {
                        "&click": function ($event) {
                          return _vm.closeCalendar.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline" },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.newVersion === true,
                                  expression: "newVersion === true",
                                },
                              ],
                            },
                            [_vm._v("Neue Version ")]
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.newVersion === false,
                                  expression: "newVersion === false",
                                },
                              ],
                            },
                            [_vm._v(" " + _vm._s(_vm.formTitle))]
                          ),
                          _c("v-spacer"),
                          _c("v-checkbox", {
                            staticClass: "mt-0 mr-3 py-0 secret",
                            attrs: { label: "Geheimschutz" },
                            on: {
                              click: function ($event) {
                                _vm.setSecretLogic(), !_vm.opportunity.isSecret
                              },
                            },
                            model: {
                              value: _vm.opportunity.isSecret,
                              callback: function ($$v) {
                                _vm.$set(_vm.opportunity, "isSecret", $$v)
                              },
                              expression: "opportunity.isSecret",
                            },
                          }),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-3 py-0",
                              on: {
                                click: function ($event) {
                                  return _vm.close(true)
                                },
                              },
                            },
                            [_vm._v("mdi-close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: {
                                "lazy-validation": "",
                                readonly:
                                  _vm.formTitle === "Opportunity bearbeiten" &&
                                  _vm.getComputacenterGroup
                                    ? true
                                    : false,
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 pl-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            ref: "opportunityName",
                                            attrs: {
                                              label: "Opportunity Name *",
                                              counter: "100",
                                              rules: _vm.ruleRequiredText,
                                              readonly:
                                                _vm.formTitle ===
                                                "Opportunity bearbeiten",
                                              "data-cy": "opportunity-name",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.opportunity.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.opportunity,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "opportunity.name",
                                            },
                                          }),
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.getCustomers,
                                              readonly:
                                                _vm.formTitle ===
                                                "Opportunity bearbeiten",
                                              rules: _vm.ruleRequiredText,
                                              "data-cy": "opportunity-customer",
                                              label: "Kunde *",
                                              "item-text": "name",
                                              "item-value": "id",
                                              outlined: "",
                                              dense: "",
                                              "return-object": "",
                                            },
                                            model: {
                                              value: _vm.customer,
                                              callback: function ($$v) {
                                                _vm.customer = $$v
                                              },
                                              expression: "customer",
                                            },
                                          }),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "min-width": "0",
                                                "close-on-click": false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "text",
                                                              attrs: {
                                                                readonly: "",
                                                                label:
                                                                  "Start Datum *",
                                                                outlined: "",
                                                                rules:
                                                                  _vm.ruleRequiredText,
                                                                dense: "",
                                                                value:
                                                                  _vm.startDateFormatted,
                                                                "data-cy":
                                                                  "opportunity-startdate",
                                                              },
                                                            },
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1945231705
                                              ),
                                              model: {
                                                value: _vm.isMenuStartDate,
                                                callback: function ($$v) {
                                                  _vm.isMenuStartDate = $$v
                                                },
                                                expression: "isMenuStartDate",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: { locale: "de" },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.isMenuStartDate = false
                                                  },
                                                },
                                                model: {
                                                  value: _vm.startDate,
                                                  callback: function ($$v) {
                                                    _vm.startDate = $$v
                                                  },
                                                  expression: "startDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              suffix: "Monate",
                                              disabled:
                                                _vm.formTitle ===
                                                "Opportunity bearbeiten"
                                                  ? true
                                                  : false,
                                              label: "Dauer *",
                                              rules: _vm.ruleNumberOrFloat,
                                              "data-cy": "opportunity-duration",
                                              outlined: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.opportunity.duration,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.opportunity,
                                                  "duration",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "opportunity.duration",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 pl-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              outlined: "",
                                              name: "input-7-4",
                                              height: "14.8rem",
                                              readonly:
                                                _vm.formTitle ===
                                                "Opportunity bearbeiten",
                                              label:
                                                "Beschreibung der Opportunity *",
                                              rules: _vm.opportunity.isSecret
                                                ? [_vm.ruleDescription[1]]
                                                : _vm.ruleDescription,
                                              counter: "500",
                                              "data-cy":
                                                "opportunity-description",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.opportunity.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.opportunity,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "opportunity.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-row", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.formTitle ===
                                              "Opportunity bearbeiten",
                                            expression:
                                              "formTitle === 'Opportunity bearbeiten'",
                                          },
                                        ],
                                        staticClass: "mt-1",
                                      }),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0 pl-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "ml-3" }, [
                                            _vm._v("* Pflichtfelder"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { staticClass: "mt-4" }),
                          _c("ServiceCard", {
                            ref: "serviceCard",
                            attrs: {
                              opportunity: _vm.opportunity,
                              isEditing:
                                _vm.formTitle === "Opportunity bearbeiten",
                            },
                            on: {
                              adoptTotalCost: function ($event) {
                                return _vm.adoptTotalCost($event)
                              },
                              travel: _vm.travelcost,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "blue darken-1",
                                text: "",
                                "data-cy": "new-opportunity-close",
                                outlined: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.close(true)
                                },
                              },
                            },
                            [_vm._v(" Zurück ")]
                          ),
                          _vm.formTitle === "Neue Opportunity" ||
                          (_vm.formTitle === "Opportunity bearbeiten" &&
                            _vm.getCiscoGroup)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "blue darken-1",
                                    text: "",
                                    outlined: "",
                                    "data-cy": "send-new-opportunity",
                                  },
                                  on: { click: _vm.openDialogSendRequest },
                                },
                                [
                                  _vm.formTitle === "Neue Opportunity"
                                    ? _c("span", [_vm._v("Absenden")])
                                    : _vm._e(),
                                  _c(
                                    "v-icon",
                                    { attrs: { right: "", dark: "" } },
                                    [_vm._v("mdi-send")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _vm.dialogSendRequest
                                  ? _c(
                                      "v-dialog",
                                      {
                                        attrs: { width: "700" },
                                        model: {
                                          value: _vm.dialogSendRequest,
                                          callback: function ($$v) {
                                            _vm.dialogSendRequest = $$v
                                          },
                                          expression: "dialogSendRequest",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "title sx-vertical-layout",
                                                staticStyle: {
                                                  color: "red",
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  "data-cy":
                                                    "send-opportunity-dialog",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "icon-center",
                                                    attrs: { color: "red" },
                                                  },
                                                  [_vm._v("mdi-alert-outline")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.noTravelcost,
                                                        expression:
                                                          "noTravelcost",
                                                      },
                                                    ],
                                                    staticClass: "alertText",
                                                    attrs: { lang: "de" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Fallen Reisekosten an, dann müssen diese als separater Posten eingetragen werden. Wollen Sie die Opportunity dennoch ohne Reisekosten absenden? "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_vm.noTravelcost,
                                                        expression:
                                                          "!noTravelcost",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Wollen Sie die Opportunity absenden?"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-actions",
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      text: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.dialogSendRequest = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Abbrechen ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      text: "",
                                                      "data-cy":
                                                        "sure-to-send-opportunity",
                                                    },
                                                    on: { click: _vm.save },
                                                  },
                                                  [_vm._v(" JA ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }