<template>
  <div>
    <sx-tooltip text="Bearbeiten">
      <v-icon small class="mr-1" @click="editItem()" v-if="getCiscoGroup" data-cy="edit-opportunity"
        v-show="canEditOpportunity">
        mdi-pencil
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="Bearbeiten">
      <v-icon small class="mr-1" @click="editItem()" v-if="getCiscoSibeGroup" data-cy="edit-opportunity"
        v-show="canEditOpportunity">
        mdi-pencil
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="Anschauen">
      <v-icon small class="mr-1" @click="editItem(), isSeeDetails = true" v-if="getCiscoGroup || getCiscoSibeGroup"
        :id="'opportunity_view_' + params.data.id" v-show="seeOpportunityDetails">
        mdi-eye
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="Anschauen">
      <v-icon :id="'opportunity_view_' + params.data.id" small class="mr-1" @click="editItem()"
        v-if="getComputacenterGroup">
        mdi-eye
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="mit neuer Version ersetzen">
      <Versioning :element="params.data" v-if="checkVersioning()" />
    </sx-tooltip>

    <sx-tooltip text="Klon erstellen">
      <Clone :element="params.data" v-if="getComputacenterGroup && !isReplaced" />
    </sx-tooltip>

    <sx-tooltip text="E-Mail Programm öffnen" v-if="!getComputacenterGroup && checkEmailButton()">
      <v-icon small class="mr-1" @click="
        onOpenMail(params.data);
      ">
        mdi-email-outline
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="E-Mail an Dienstverträge-Mailbox senden" v-if="getComputacenterGroup && checkEmailButton()">
      <v-icon small class="mr-1" @click="
        setCheckRelease(generell_dv);
      onOpenMail(params.data);
      ">
        $iconEmailDv
      </v-icon>
    </sx-tooltip>

    <sx-tooltip text="Angebot generieren">
      <GenerateDocument :element="params.data" v-if="getComputacenterGroup" />
    </sx-tooltip>

    <v-dialog v-model="dialog" width="55rem" scrollable persistent v-show="dialog">
      <v-card v-if="dialog" elevation="0" @click.passive="closeCalendar">
        <v-card-title class="headline">
          <span>{{ formTitle }}</span>
          <span>- {{ params.data.version }}</span>
          <v-spacer></v-spacer>
          <span v-if="isReplaced" class="isReplaced">ersetzt</span>
          <v-spacer></v-spacer>

          <v-checkbox v-model="opportunity.isSecret" class="mt-0 mr-3 py-0 secret editSecret" label="Geheimschutz"
            v-if="opportunity.isSecret" disabled readonly>
          </v-checkbox>

          <v-divider vertical></v-divider>
          <v-icon @click="close" class="ml-3 py-0">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation :readonly="formTitle === 'Opportunity bearbeiten' &&
            getComputacenterGroup &&
            params.data.isEdited
            ">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <v-text-field v-model="opportunity.name" label="Opportunity Name"
                    :disabled="formTitle === 'Opportunity bearbeiten'" :rules="ruleRequiredText"
                    :readonly="formTitle === 'Opportunity bearbeiten'" outlined dense></v-text-field>
                  <v-autocomplete item-text="name" :items="getCustomers" v-model="opportunity.customerName"
                    :disabled="formTitle === 'Opportunity bearbeiten'" :readonly="formTitle === 'Opportunity bearbeiten'"
                    :rules="ruleRequiredText" label="Kunde" outlined dense></v-autocomplete>

                  <v-menu min-width="0" v-model="isMenuStartDate" :close-on-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field readonly v-on="on" :disabled="formTitle === 'Opportunity bearbeiten'"
                        label="Start Datum *" class="text" outlined dense :value="startDateFormatted"></v-text-field>
                    </template>
                    <v-date-picker v-model="opportunity.startDate" @input="isMenuStartDate = false" locale="de">
                    </v-date-picker>
                  </v-menu>

                  <v-text-field type="number" :readonly="params.data.isEdited" v-model="opportunity.duration"
                    suffix="Monate" :disabled="formTitle === 'Opportunity bearbeiten'" label="Dauer *"
                    :rules="ruleNumberOrFloat" outlined dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <v-textarea outlined name="input-7-4" height="14.8rem"
                    :readonly="formTitle === 'Opportunity bearbeiten'" :disabled="formTitle === 'Opportunity bearbeiten'"
                    label="Beschreibung der Opportunity" v-model="opportunity.description" dense></v-textarea>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <v-select :items="getEditableStates" v-model="inputState" :readonly="params.data.isEdited"
                    :rules="ruleRequiredText" item-value="name" item-text="name" :disabled="!canSelect"
                    data-cy="opportunity-state" label="Status *" outlined dense></v-select>

                  <v-textarea outlined :readonly="params.data.isEdited" name="input-7-4" label="Kommentar Cisco"
                    :rules="inputState === 'Abgelehnt' ? ruleRequiredText : []" :disabled="formTitle === 'Neue Opportunity' ||
                      (getComputacenterGroup
                        ? true
                        : false && formTitle === 'Opportunity bearbeiten') ||
                      isReplaced ||
                      params.data.isEdited
                      " v-show="inputState || opportunity.stateDescription" data-cy="opportunity-state-comment"
                    v-model="opportunity.stateDescription" dense></v-textarea>
                </v-col>

                <v-col>
                  <v-row>
                    <v-col class="pa-0" cols="12" sm="6" md="6">
                      <span style="color: #bdbdbd; font-size: 0.9rem">Erstellt von:
                      </span>
                      <span class="ml-1" style="color: #757575; font-size: 0.9rem">{{ opportunity.createdBy }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="pa-0" cols="12" sm="6" md="6">
                      <span style="color: #bdbdbd; font-size: 0.9rem">Erstellt am:
                      </span>
                      <span class="ml-1" style="color: #757575; font-size: 0.9rem">{{ formattedCreatedAt }}</span>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <span class="ml-3">* Pflichtfelder</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-divider class="mt-4"></v-divider>
          <ServiceCard :isEditing="formTitle === 'Opportunity bearbeiten'"
            :readonly="formTitle === 'Opportunity bearbeiten'" :opportunity="opportunity" ref="serviceCard" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close" outlined>
            Zurück
          </v-btn>

          <v-btn color="blue darken-1" text @click="openDialogSendRequest" outlined
            v-if="getCiscoGroup && !isReplaced || getCiscoSibeGroup && params.data.isSecret"
            v-show="!params.data.isEdited && !isSeeDetails" data-cy="opportunity-send-update-btn">
            <span>Absenden</span>
            <v-icon right dark>mdi-send</v-icon>
          </v-btn>

          <template>
            <div class="text-center">
              <v-dialog v-if="dialogSendRequest" v-model="dialogSendRequest" width="500">
                <v-card>
                  <v-card-title class="title sx-vertical-layout" style="color: red"
                    data-cy="send-updated-opportunity-dialog">
                    <v-icon class="icon-center" color="red">mdi-alert-outline</v-icon>
                    Wollen Sie die Opportunity absenden?
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogSendRequest = false">
                      Abbrechen
                    </v-btn>
                    <v-btn color="primary" text @click="save" data-cy="sure-to-send-updated-opportunity">
                      Ja
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ServiceCard from "@/components/content/ServiceCard";
import WonLost from "@/components/content/WonLost";
import Versioning from "@/components/content/Versioning";
import GenerateDocument from "@/components/content/GenerateDocument";
import Clone from "@/components/content/Clone";
import { opportunityService } from "@/services";
import { HISTORY_STATE } from "@/helper/historyStates";

export default {
  name: "EditOpportunity",
  oppID: null,
  components: {
    /* eslint-disable vue/no-unused-components */
    ServiceCard,
    WonLost,
    Versioning,
    Clone,
    GenerateDocument
  },

  data: () => ({
    columnDefs: null,
    dialog: false,
    search: "",
    valid: true,
    icons: null,
    formTitle: "Opportunity bearbeiten",
    //counter: 50,
    dialogDelete: false,
    dialogSendRequest: false,
    dialogRelease: false,
    checkRelease: "",
    item: null,
    isEdited: true,
    inputState: "",
    generell_dv: "generell_dv",

    isSeeDetails: false,
    // Rule that checks if a field contains any input.
    ruleRequiredText: [(value) => !!value || "Das Feld darf nicht leer sein."],
    ruleNumberOrFloat: [
      (v) =>
        /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
        "Es sind nur Dezimalzahlen erlaubt.",
    ],
    // Flag to toggle the date picker for proposaldate.
    isMenuStartDate: false,
    //states: ["New", "Approved", "Inquiry", "Declined"],
    headers: [
      { field: "ID", value: "id" },
      { field: "Modified At", value: "modifiedAt" },
      { field: "Created By", value: "createdBy" },
      {
        field: "Opportunity Name",
        value: "name",
      },
      { field: "Customer", value: "customerName" },
      { field: "State", value: "state" },
      { field: "State Description", value: "description" },
      { field: "Total Cost", value: "totalCost" },
      { field: "Actions", value: "actions", sortable: false },
    ],

    editedIndex: -1,
    opportunity: {
      id: "00000000-0000-0000-0000-000000000000",
      createdAt: "",
      creatorUserEmail: "",
      createdBy: "",
      modifiedAt: null,
      modifiedBy: "",
      name: "",
      customerName: "",
      state: "Neu",
      description: "",
      totalCost: null,
      stateDescription: "",
      startDate: "",
      duration: "",
      cC_KAM: "",
      cisco_Emails: "",
      isSecret: false
    },
    defaultItem: {
      id: "00000000-0000-0000-0000-000000000000",
      createdAt: "",
      creatorUserEmail: "",
      createdBy: "",
      modifiedAt: null,
      modifiedBy: "",
      name: "",
      customerName: "",
      state: "",
      description: "",
      totalCost: null,
      stateDescription: "",
      startDate: "",
      duration: "",
      cC_KAM: "",
      cisco_Emails: "",
      isSecret: false
    },
  }),

  computed: {
    ...mapGetters("user", [
      "getComputacenterGroup",
      "getCiscoGroup",
      "getAdminGroup",
      "getCiscoSibeGroup"
    ]),
    ...mapGetters("customer", ["getCustomers"]),
    ...mapGetters("opportunity", ["opportunities", "getOpportunity"]),
    ...mapGetters("opportunityState", ["getEditableStates"]),

    oppIsEdited() {
      return this.isEdited;
    },

    isReplaced() {
      return this.params.data.history.find(
        (item) => item.state.orderNumber == HISTORY_STATE.Ersetzt
      )
        ? true
        : false;
    },

    canSelect() {
      if (this.formTitle === 'Neue Opportunity') return false;
      if (this.isReplaced) return false;
      if (this.params.data.isEdited) return false;
      if (this.getCiscoGroup && !this.params.data.isSecret) return true;
      if (this.getCiscoSibeGroup && this.params.data.isSecret) return true;
      return false;
    },


    isLost() {
      return this.params.data.history.find(
        (item) => item.state.orderNumber == HISTORY_STATE.Verloren
      )
        ? true
        : false;
    },

    isNew() {
      return this.params.data.history.find(
        (item) => item.state.orderNumber == HISTORY_STATE.Neu
      )
        ? true
        : false;
    },

    canEditOpportunity() {
      if (this.getCiscoSibeGroup && !this.params.data.isEdited && !this.isReplaced && !this.isLost && this.params.data.isSecret) return true;
      if (this.getCiscoGroup && !this.params.data.isEdited && !this.params.data.isSecret && !this.isReplaced && !this.isLost) return true;
      return false;
    },

    seeOpportunityDetails() {
      if (this.getCiscoGroup && this.params.data.isSecret || this.params.data.isEdited || this.isReplaced || this.isLost) return true
      if (this.getCiscoSibeGroup && this.isNew && !this.params.data.isSecret || this.params.data.isEdited || this.isReplaced || this.isLost) return true;
      return false;
    },

    startDateFormatted: {
      get() {
        return this.formatDate(this.opportunity.startDate);
      },
      set() { },
    },

    formattedCreatedAt() {
      return this.formatDate(this.opportunity.createdAt);
    },

    formattedModifiedAt() {
      return this.formatDate(this.opportunity.modifiedAt);
    },
  },

  methods: {
    setCheckRelease(status) {
      this.checkRelease = status;
    },

    ...mapActions("opportunity", ["setItems", "editOpportunityItem"]),

    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    edited() {
      return (this.isEdited = false);
    },

    // Format the date from an ISO string to common german format.
    formatDate(date) {
      if (!date) return null;

      const datePart = date.split("T")[0];
      const [year, month, day] = datePart.split("-");

      return `${day}.${month}.${year}`;
    },

    closeCalendar() {
      this.isMenuStartDate = false;
    },

    strToFloat(value) {
      if (value === "") {
        return 0;
      } else {
        const parseResult = parseFloat(value);
        return isNaN(parseResult) ? 0 : parseResult;
      }
    },

    getStatePayload() {
      if (this.opportunity.history.length <= 2 && ["Qualifiziert", "Abgelehnt"].includes(this.inputState)) {
        const modifiedBy = this.$store.state.userInfo.fullname;
        return {
          modifiedBy,
          modifiedAt: new Date().toISOString(),
          comment: this.opportunity.stateDescription,
          state: this.getStateByOrderNumber(this.inputState === "Qualifiziert" ? 2 : 3),
        };
      }
    },

    getPayload() {
      let id = this.opportunity.id
        ? this.opportunity.id
        : "00000000-0000-0000-0000-000000000000";
      let createdBy = this.opportunity.createdBy;
      let modifiedBy = this.$store.state.userInfo.fullname;

      // set createdBy
      if (this.formTitle === "Neue Opportunity") {
        createdBy = this.$store.state.userInfo.fullname;
      }

      // Caution - task by backend
      return {
        id: id,
        createdAt: this.opportunity.createdAt,
        creatorUserEmail: this.opportunity.creatorUserEmail,
        createdBy: createdBy,
        modifiedAt: new Date().toISOString(),
        modifiedBy: modifiedBy,
        name: this.opportunity.name,
        customerName: this.opportunity.customerName,
        state: this.opportunity.state,
        description: this.opportunity.description,
        totalCost: this.strToFloat(this.opportunity.totalCost),
        version: this.opportunity.version,
        stateDescription: this.opportunity.stateDescription,
        startDate: this.opportunity.startDate,
        duration: this.strToFloat(this.opportunity.duration),
        cC_KAM: this.opportunity.cC_KAM,
        cisco_Emails: this.opportunity.cisco_Emails,
        wonOrLostState: this.opportunity.wonOrLostState,
        history: this.opportunity.history,
        isEdited: true,
        isSecret: this.opportunity.isSecret,
      };
    },

    assignCommentToDescription() {
      if (this.opportunity.history.length > 0) {
        const latestHistory = this.opportunity.history[this.opportunity.history.length - 1];
        const comment = latestHistory.comment;
        if (comment) {
          this.opportunity.description = comment;
        }
      }
    },

    editItem() {
      // this.params.data to get row object/values from ag-grid
      // find the opportunity index
      this.editedIndex =
        this.$store.state.oppAndService.oppAndServices.findIndex(
          (opportunity) => opportunity.id === this.params.data.id
        );

      this.opportunity = Object.assign({}, this.params.data);

      this.assignCommentToDescription();

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex =
        this.$store.state.oppAndService.oppAndServices.indexOf(item);
      this.opportunity = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.state.oppAndService.oppAndServices.splice(
        this.editedIndex,
        1
      );
      this.closeDelete();
    },

    close() {
      this.isSeeDetails = false;
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.opportunity = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.setItems(this.$store.state.oppAndService.oppAndServices);
    },

    openDialogSendRequest() {
      if (this.$refs.form.validate()) {
        this.dialogSendRequest = true;
      }
    },

    async onOpenMail(data) {
      let additionalState = null;

      if (this.getComputacenterGroup && this.checkRelease === "release") {
        await opportunityService.patchHistoryRecord(this.params.data.id, {
          modifiedBy: this.$store.state.userInfo.fullname,
          modifiedAt: new Date().toISOString(),
          state: this.getStateByOrderNumber(4),
        });
      } else if (this.getComputacenterGroup && this.checkRelease === "generell_dv") {
        additionalState = "_allgemein_dv";
      }

      this.dialogRelease = false;
      const template = this.$store.getters["app/getMailTemplate"]({
        cisco: this.getCiscoGroup,
        cc: this.getComputacenterGroup,
        path: "opp",
        data,
        additionalState,
      });
      this.$store.dispatch("openMail", {
        template,
        scope: data,
      });
    },

    checkEmailButton() {
      let orderNumber = "";
      for (let history of this.params.data.history) {
        orderNumber = history.state.orderNumber;
      }
      if (orderNumber === 8) {
        return false;
      } else {
        return true;
      }
    },

    checkVersioning() {
      const { history } = this.params.data;
      const orderNumber = history[history.length - 1]?.state.orderNumber;
      return orderNumber < 6 && !this.getCiscoGroup && !this.getCiscoSibeGroup;
    },

    async save() {
      let statePayload = this.getStatePayload();

      let payload = this.getPayload();

      await opportunityService.patchHistoryRecord(payload.id, statePayload);

      await opportunityService.setIsEdited(payload.id);

      this.dialogSendRequest = false;
      this.inputState = "";
      this.close();
    },
  },

  watch: {
    opportunity() {
      for (let history of this.opportunity.history) {
        if (history.state.orderNumber === 1) {
          this.opportunity.description = history.comment;
        } else if (history.state.orderNumber === 3) {
          this.inputState = history.state.name;
        } else {
          this.inputState = this.getStateByOrderNumber(2).name;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.editSecret {
  pointer-events: none;
}

.isReplaced {
  font-size: 1rem;
  color: red;
}

.custom-icon {
  fill: currentColor
}
</style>
