var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.$store.getters["contract/isDisabledContract"],
                          expression:
                            "!$store.getters['contract/isDisabledContract']",
                        },
                      ],
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "blue darken-1" } }, [
                    _vm._v("mdi-information-outline"),
                  ]),
                  _vm._v("Cisco E-Mails "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-icon", { attrs: { color: "blue darken-1" } }, [
                _vm._v("mdi-information-outline"),
              ]),
              _vm._v(" Cisco E-Mails "),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-5 pb-0" },
            _vm._l(_vm.infos, function (info) {
              return _c("div", { key: info.id }, [
                info.name == _vm.$store.getters["contract/getContract"].name
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "Für den " +
                            _vm._s(info.nameDisplay) +
                            " ist in folgenden Fällen Cisco zu informieren oder anzuschreiben: "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(" 1. Bei Anfragen des Kunden "),
                        info.name == "Netzwerk 21082"
                          ? _c("span", [_vm._v(" (nur schriftliche)")])
                          : _vm._e(),
                        _c("span", [_vm._v(": "), _c("br")]),
                        info.name !== "UCC 21587"
                          ? _c(
                              "span",
                              [
                                _vm._v("Kopie weiterleiten an "),
                                _c("b", [_vm._v(_vm._s(info.requestCustomer))]),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "blue darken-1",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText(
                                          info.requestCustomer
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        info.name == "UCC 21587"
                          ? _c(
                              "span",
                              [
                                _vm._v("Weiterleiten an "),
                                _c("b", [_vm._v(_vm._s(info.requestCustomer))]),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "blue darken-1",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyText(
                                          info.requestCustomer
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _vm._v(
                                  " ➝ Cisco erstellt eine Unique ID und leitet die Anfrage an den richtigen Partner weiter"
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "p",
                        [
                          _vm._v("2. Beim Versenden der Angebote: "),
                          _c("br"),
                          _vm._v("Kopie an "),
                          _c("b", [_vm._v(_vm._s(info.offer))]),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", color: "blue darken-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(info.offer)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _vm._v("3. Bei Bitte um Freigabe: "),
                          _c("br"),
                          _vm._v("Mail an "),
                          _c("b", [_vm._v(_vm._s(info.approval))]),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", color: "blue darken-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(info.approval)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _vm._v("4. Bei Versenden der Auftragsbestätigung: "),
                          _c("br"),
                          _vm._v("Kopie an "),
                          _c("b", [_vm._v(_vm._s(info.orderConformation))]),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", color: "blue darken-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(info.orderConformation)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", outlined: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Zurück ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }