var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { elevation: "0" },
      on: {
        "&click": function ($event) {
          return _vm.closeCalendar.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card-title",
        [
          _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
          _c("v-spacer"),
          _c("span", { staticClass: "isReplaced mr-3" }, [
            _vm._v(_vm._s(_vm.ersetztText)),
          ]),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c("v-icon", { staticClass: "ml-3 py-0", on: { click: _vm.close } }, [
            _vm._v("mdi-close"),
          ]),
        ],
        1
      ),
      _c("v-card-subtitle", { staticClass: "subtitleText" }, [
        _vm._v(
          " " +
            _vm._s(_vm.element.version) +
            " - " +
            _vm._s(_vm.element.customerName) +
            " "
        ),
      ]),
      _vm.isLoading
        ? _c("v-progress-circular", {
            staticClass: "wonlost-loader",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-card-text",
              {
                staticClass: "card-content",
                staticStyle: { padding: "0 24px 12px" },
              },
              [
                _c(
                  "v-row",
                  { staticClass: "wonlost-body" },
                  [
                    _c(
                      "v-col",
                      [
                        _c("States", {
                          attrs: {
                            realTotalCost: _vm.realTotalCost,
                            opportunity: _vm.element,
                          },
                          on: {
                            onWonLostChanged: function ($event) {
                              return _vm.onWonLostChanged($event)
                            },
                            onLostComment: function ($event) {
                              return _vm.onLostComment($event, _vm.lostComment)
                            },
                            emitDialogClose: _vm.close,
                          },
                          model: {
                            value: _vm.historyState,
                            callback: function ($$v) {
                              _vm.historyState = $$v
                            },
                            expression: "historyState",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isStatusDialog && _vm.state == 0,
                            expression: "!isStatusDialog && state == 0",
                          },
                        ],
                        staticClass: "wonlost-scrollable",
                      },
                      [
                        _c(
                          "v-form",
                          { ref: "form", attrs: { disabled: !_vm.canSetLost } },
                          [
                            _vm.state === 0
                              ? [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-6 py-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            md: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "min-width": "0",
                                                    "close-on-click": false,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "text mt-5",
                                                                  attrs: {
                                                                    readonly:
                                                                      "",
                                                                    label:
                                                                      "Bestelldatum *",
                                                                    rules:
                                                                      _vm.ruleRequiredText,
                                                                    disabled:
                                                                      _vm.getCiscoGroup
                                                                        ? true
                                                                        : false,
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    value:
                                                                      _vm.formatDate(
                                                                        _vm
                                                                          .wonInformation
                                                                          .orderDate
                                                                      ),
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2883337442
                                                  ),
                                                  model: {
                                                    value: _vm.isMenuOrderDate,
                                                    callback: function ($$v) {
                                                      _vm.isMenuOrderDate = $$v
                                                    },
                                                    expression:
                                                      "isMenuOrderDate",
                                                  },
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: { locale: "de" },
                                                    on: {
                                                      input: function ($event) {
                                                        _vm.isMenuStartDate = false
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.wonInformation
                                                          .orderDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.wonInformation,
                                                          "orderDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "wonInformation.orderDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    "Kundenbestellnummer *",
                                                  rules: _vm.ruleRequiredText,
                                                  disabled: _vm.getCiscoGroup
                                                    ? true
                                                    : false,
                                                  outlined: "",
                                                  dense: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.wonInformation
                                                      .customerOrderId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.wonInformation,
                                                      "customerOrderId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "wonInformation.customerOrderId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c("sx-currency-input", {
                                                attrs: {
                                                  rules: _vm.ruleRequiredText,
                                                  disabled: true,
                                                  label: "Gesamtsumme",
                                                },
                                                model: {
                                                  value: _vm.realTotalCost,
                                                  callback: function ($$v) {
                                                    _vm.realTotalCost = $$v
                                                  },
                                                  expression: "realTotalCost",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("v-row", [
                                            _c("small", [
                                              _vm._v("* Pflichtfelder"),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "mt-10" }),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "pl-0" },
                                        [_vm._v("Dienstleistungen")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-simple-table", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function () {
                                                return [
                                                  _c("thead", [
                                                    _c(
                                                      "tr",
                                                      { staticClass: "br" },
                                                      [
                                                        _c("th"),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left text-header",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Qualifikation"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left text-header",
                                                          },
                                                          [_vm._v("Tage")]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left text-header",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Preis pro Tag"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticClass:
                                                              "text-left text-header",
                                                          },
                                                          [_vm._v("Summe in €")]
                                                        ),
                                                        _c("th"),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "tbody",
                                                    [
                                                      _vm.services != null
                                                        ? [
                                                            _vm._l(
                                                              _vm.services
                                                                .services
                                                                .length,
                                                              function (
                                                                n,
                                                                index
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "tr",
                                                                    {
                                                                      key:
                                                                        "service_" +
                                                                        index,
                                                                      staticClass:
                                                                        "no-border no-hover",
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          "genehmigt:"
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .services
                                                                              .services[
                                                                              index
                                                                            ]
                                                                              .skillName
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .services
                                                                              .services[
                                                                              index
                                                                            ]
                                                                              .days
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "formatNumber"
                                                                              )(
                                                                                _vm
                                                                                  .services
                                                                                  .services[
                                                                                  index
                                                                                ]
                                                                                  .pricePerDay,
                                                                                "0,0.00 $"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "formatNumber"
                                                                              )(
                                                                                _vm
                                                                                  .services
                                                                                  .services[
                                                                                  index
                                                                                ]
                                                                                  .sum,
                                                                                "0,0.00 $"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                      _c("td"),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "tr",
                                                                    {
                                                                      key:
                                                                        "ordered_service_" +
                                                                        index,
                                                                      staticClass:
                                                                        "no-hover",
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          "beauftragt:"
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .services
                                                                                .orderedServices[
                                                                                index
                                                                              ]
                                                                                .skillName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]),
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              key: index,
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.getCiscoGroup
                                                                                      ? true
                                                                                      : false,
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  function () {
                                                                                    return _vm.updatePrices(
                                                                                      _vm
                                                                                        .services
                                                                                        .orderedServices[
                                                                                        index
                                                                                      ]
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .services
                                                                                      .orderedServices[
                                                                                      index
                                                                                    ]
                                                                                      .days,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .services
                                                                                          .orderedServices[
                                                                                          index
                                                                                        ],
                                                                                        "days",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "services.orderedServices[index].days",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "sx-currency-input",
                                                                            {
                                                                              key: index,
                                                                              staticClass:
                                                                                "currency-input",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.getCiscoGroup
                                                                                      ? true
                                                                                      : false,
                                                                                  clearable:
                                                                                    _vm.getComputacenterGroup
                                                                                      ? true
                                                                                      : false,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .services
                                                                                      .orderedServices[
                                                                                      index
                                                                                    ]
                                                                                      .pricePerDay,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .services
                                                                                          .orderedServices[
                                                                                          index
                                                                                        ],
                                                                                        "pricePerDay",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "\n                                  services.orderedServices[index].pricePerDay\n                                ",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "sx-currency-input",
                                                                            {
                                                                              key: index,
                                                                              staticClass:
                                                                                "currency-input",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.getCiscoGroup
                                                                                      ? true
                                                                                      : false,
                                                                                  clearable:
                                                                                    _vm.getComputacenterGroup
                                                                                      ? true
                                                                                      : false,
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.sumInput(
                                                                                      _vm
                                                                                        .services
                                                                                        .orderedServices[
                                                                                        index
                                                                                      ]
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .services
                                                                                      .orderedServices[
                                                                                      index
                                                                                    ]
                                                                                      .sum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .services
                                                                                          .orderedServices[
                                                                                          index
                                                                                        ],
                                                                                        "sum",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "services.orderedServices[index].sum",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          class:
                                                                            _vm.isSumUnequal(
                                                                              _vm
                                                                                .services
                                                                                .orderedServices[
                                                                                index
                                                                              ]
                                                                            ) &&
                                                                            !_vm.isSumInput
                                                                              ? "mark-red"
                                                                              : "",
                                                                          staticStyle:
                                                                            {
                                                                              "text-align":
                                                                                "center",
                                                                            },
                                                                        },
                                                                        [
                                                                          (
                                                                            _vm.getComputacenterGroup
                                                                              ? true
                                                                              : false
                                                                          )
                                                                            ? [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "formatNumber"
                                                                                          )(
                                                                                            _vm.calculateTotalSum(
                                                                                              _vm
                                                                                                .services
                                                                                                .orderedServices[
                                                                                                index
                                                                                              ]
                                                                                            ),
                                                                                            "0,0.00 $"
                                                                                          )
                                                                                        )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        display:
                                                                                          "flex",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "left-arrow",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.applyCalculatedTotalSum(
                                                                                                index
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " mdi-arrow-left-bold"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "übernehmen"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            : _vm._e(),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4070371301
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.isStatusDialog || _vm.historyState
              ? _c(
                  "v-card-actions",
                  { staticClass: "wonlost-actions" },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isThresholdExceeded && _vm.state == 0,
                            expression: "isThresholdExceeded && state == 0",
                          },
                        ],
                        staticClass: "threshold",
                      },
                      [
                        _c("v-icon", { attrs: { color: "red" } }, [
                          _vm._v("mdi-alert"),
                        ]),
                        _vm._v(
                          " Die berichtete Summe übersteigt die genehmigte Summe von " +
                            _vm._s(
                              _vm._f("formatNumber")(
                                _vm.opportunity.totalCost,
                                "0,0.00 $"
                              )
                            ) +
                            " um mehr als 10%."
                        ),
                        _c("br"),
                        _vm._v(
                          " Nach Rahmenvertrag muss eine neue Version der Opportunity mit den aktuellen Werten erstellt werden. "
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "blue darken-1", text: "" },
                        on: { click: _vm.close },
                      },
                      [_vm._v("Zurück")]
                    ),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.canSetLost,
                            expression: "canSetLost",
                          },
                        ],
                        attrs: {
                          color: "blue darken-1",
                          text: "",
                          disabled: _vm.isThresholdExceeded,
                        },
                        on: { click: _vm.openDialogSendRequest },
                      },
                      [_vm._v("Speichern ")]
                    ),
                    [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.dialogSendRequest
                            ? _c(
                                "v-dialog",
                                {
                                  attrs: { width: "500" },
                                  model: {
                                    value: _vm.dialogSendRequest,
                                    callback: function ($$v) {
                                      _vm.dialogSendRequest = $$v
                                    },
                                    expression: "dialogSendRequest",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "title sx-vertical-layout",
                                          staticStyle: { color: "red" },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "icon-center",
                                              attrs: { color: "red" },
                                            },
                                            [_vm._v("mdi-alert-outline")]
                                          ),
                                          _vm._v(
                                            " Wollen Sie die Änderungen speichern? "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogSendRequest = false
                                                },
                                              },
                                            },
                                            [_vm._v("Zurück")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.save },
                                            },
                                            [_vm._v("Ja")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }