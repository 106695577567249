<template>
  <div v-if="getComputacenterGroup" class="text-right">
    <v-tooltip :disabled="!$store.getters['contract/isDisabledContract']" bottom>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn color="primary" class="white--text" @click="open" v-show="getComputacenterGroup"
            data-cy="new-opportunity" :disabled="$store.getters['contract/isDisabledContract']">
            Neue Opportunity
          </v-btn>
        </div>
      </template>
      <span>Dieser Vertrag ist bereits unterzeichnet, weitere neue Opportunities werden nicht mehr aufgenommen.</span>
    </v-tooltip>

    <v-dialog v-model="dialog" width="55rem" scrollable persistent>
      <v-card v-if="dialog" elevation="0" @click.passive="closeCalendar">
        <v-card-title class="headline">
          <span v-show="newVersion === true">Neue Version </span>
          <span v-show="newVersion === false"> {{ formTitle }}</span>

          <v-spacer></v-spacer>

          <v-checkbox v-model="opportunity.isSecret" class="mt-0 mr-3 py-0 secret" label="Geheimschutz"
            @click="setSecretLogic(), !opportunity.isSecret">
          </v-checkbox>

          <v-divider vertical></v-divider>
          <v-icon @click="close(true)" class="ml-3 py-0">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation :readonly="
            formTitle === 'Opportunity bearbeiten' && getComputacenterGroup
              ? true
              : false
          ">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <v-text-field v-model="opportunity.name" label="Opportunity Name *" counter="100"
                    :rules="ruleRequiredText" :readonly="formTitle === 'Opportunity bearbeiten'"
                    data-cy="opportunity-name" ref="opportunityName" outlined dense></v-text-field>

                  <v-autocomplete :items="getCustomers" v-model="customer"
                    :readonly="formTitle === 'Opportunity bearbeiten'" :rules="ruleRequiredText"
                    data-cy="opportunity-customer" label="Kunde *" item-text="name" item-value="id" outlined dense
                    return-object></v-autocomplete>

                  <v-menu min-width="0" v-model="isMenuStartDate" :close-on-click="false">
                    <template v-slot:activator="{ on }">
                      <v-text-field readonly v-on="on" label="Start Datum *" class="text" outlined
                        :rules="ruleRequiredText" dense :value="startDateFormatted" data-cy="opportunity-startdate">
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="isMenuStartDate = false" locale="de"></v-date-picker>
                  </v-menu>

                  <v-text-field type="number" v-model.number="opportunity.duration" suffix="Monate" :disabled="
                    formTitle === 'Opportunity bearbeiten' ? true : false
                  " label="Dauer *" :rules="ruleNumberOrFloat" data-cy="opportunity-duration" outlined dense>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <v-textarea outlined name="input-7-4" height="14.8rem"
                    :readonly="formTitle === 'Opportunity bearbeiten'" label="Beschreibung der Opportunity *" :rules="opportunity.isSecret ? [ruleDescription[1]] : ruleDescription"
                    counter="500" v-model="opportunity.description" data-cy="opportunity-description" dense></v-textarea>
                </v-col>

                <v-row class="mt-1" v-show="formTitle === 'Opportunity bearbeiten'">
                </v-row>
                <v-col cols="12" sm="6" md="6" class="py-0 pl-0">
                  <span class="ml-3">* Pflichtfelder</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-divider class="mt-4"></v-divider>
          <ServiceCard ref="serviceCard" :opportunity="opportunity" @adoptTotalCost="adoptTotalCost($event)"
            :isEditing="formTitle === 'Opportunity bearbeiten'" @travel="travelcost" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close(true)" data-cy="new-opportunity-close" outlined>
            Zurück
          </v-btn>

          <v-btn color="blue darken-1" text @click="openDialogSendRequest" outlined v-if="
            formTitle === 'Neue Opportunity' ||
            (formTitle === 'Opportunity bearbeiten' && getCiscoGroup)
          " data-cy="send-new-opportunity">
            <span v-if="formTitle === 'Neue Opportunity'">Absenden</span>

            <v-icon right dark>mdi-send</v-icon>
          </v-btn>

          <template>
            <div class="text-center">
              <v-dialog v-if="dialogSendRequest" v-model="dialogSendRequest" width="700">
                <v-card>
                  <v-card-title class="title sx-vertical-layout" style="color: red; text-align: center"
                    data-cy="send-opportunity-dialog">
                    <v-icon class="icon-center" color="red">mdi-alert-outline</v-icon>
                    <span v-show="noTravelcost" class="alertText" lang="de">
                      Fallen Reisekosten an, dann müssen diese als separater
                      Posten eingetragen werden. Wollen Sie die Opportunity
                      dennoch ohne Reisekosten absenden?
                    </span>
                    <span v-show="!noTravelcost">Wollen Sie die Opportunity absenden?</span>
                  </v-card-title>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialogSendRequest = false">
                      Abbrechen
                    </v-btn>
                    <v-btn color="primary" text @click="save" data-cy="sure-to-send-opportunity">
                      JA
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { opportunityService } from "@/services";
import { HISTORY_STATE } from "../../helper/historyStates";
import ServiceCard from "@/components/content/ServiceCard";

export default {
  name: "NewOpportunity",

  components: {
    ServiceCard,
  },

  data() {
    return {
      dialog: false,
      formTitle: "Neue Opportunity",
      dialogSendRequest: false,
      // Rule that checks if a field contains any input.
      ruleRequiredText: [
        (value) => !!value || "Das Feld darf nicht leer sein.",
      ],

      ruleDescription: [
        (value) => !!value || "Das Feld darf nicht leer sein.",
        (value) => value.length <= 500 || "Es sind nur max. 500 Zeichen erlaubt.",
      ],

      ruleNumberOrFloat: [
        (v) =>
          /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) ||
          "Es sind nur Dezimalzahlen erlaubt.",
      ],
      isValidForm: false,
      isValidServices: false,

      // Flag to toggle the date picker for proposaldate.0
      isMenuStartDate: false,

      clonedOrVersionData: null,

      newVersion: false,

      noTravelcost: false,
      customer: null,

      opportunity: this.getDefaultOpportunity(),
      defaultItem: this.getDefaultOpportunity(),
    };
  },

  mounted() {
    if (window.Cypress) {
      window.NewOpportunity = this;
    }
  },

  computed: {
    ...mapGetters("user", [
      "getComputacenterGroup",
      "getCiscoGroup",
      "getAdminGroup",
    ]),
    ...mapGetters("customer", ["getCustomers", "getCustomer"]),
    ...mapGetters("opportunity", ["getOpportunity"]),

    isValid() {
      return this.isValidForm && this.isValidServices;
    },

    /*     isValid() {
          if (this.opportunity.isSecret) return true;
          else return this.isValidForm && this.isValidServices ? true : false;
        }, */

    startDate: {
      get() {
        return this.opportunity.startDate ? new Date(this.opportunity.startDate).toISOString().split("T")[0] : null;
      },
      set(value) {
        this.opportunity.startDate = value;
      },
    },

    totalCost: {
      get() {
        return this.$options.filters.formatNumber(
          this.opportunity.totalCost,
          "0,0.00"
        ) === "0"
          ? ""
          : this.$options.filters.formatNumber(
            this.opportunity.totalCost,
            "0,0.00"
          );
      },
      set(newValue) {
        this.opportunity.totalCost = newValue;
      },
    },

    startDateFormatted: {
      get() {
        return this.formatDate(this.opportunity.startDate);
      },
    },

    formattedCreatedAt() {
      return this.formatDate(this.opportunity.createdAt);
    },

    formattedModifiedAt() {
      return this.formatDate(this.opportunity.modifiedAt);
    },
  },

  methods: {

    getDefaultOpportunity() {
      return {
        id: "00000000-0000-0000-0000-000000000000",
        createdAt: "",
        creatorUserEmail: "",
        createdBy: "",
        modifiedAt: null,
        modifiedBy: "",
        name: "",
        customerName: "",
        state: "",
        description: "",
        totalCost: null,
        stateDescription: "",
        startDate: "",
        duration: "",
        isEdited: false,
        version: "",
        cC_KAM: "",
      };
    },

    setSecretLogic() {

      if (this.opportunity.isSecret) {
        this.opportunity.name = "Opportunity unter Geheimschutz";
      } else {
        // reset opportunity name
        this.opportunity.name = null;
        // reset the textfield. Otherwise the validation is getting triggered
        this.$refs.opportunityName.reset()
      }

    },

    travelcost(value) {
      this.noTravelcost = value;
    },

    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    adoptTotalCost(sum) {
      this.opportunity.totalCost = sum;
    },

    // Format the date from an ISO string to common german format.
    formatDate(date) {
      if (!date) return null;

      const datePart = date.split("T")[0];
      const [year, month, day] = datePart.split("-");

      return `${day}.${month}.${year}`;
    },

    async open(clonedOrVersionData) {
      if (clonedOrVersionData.state) {
        this.clonedOrVersionData = clonedOrVersionData;

        this.opportunity = this.clonedOrVersionData.oldOpportunity;
        if (this.clonedOrVersionData.isNewVersion) {
          this.opportunity.parentOpportunityId = this.opportunity.id;
        }

        this.customer = this.getCustomer(this.opportunity.customerName);

        const neu = this.opportunity.history.find((h) => h.state.orderNumber == HISTORY_STATE.Neu);
        if (neu) this.opportunity.description = neu.comment;
      }

      this.dialog = true;
    },

    closeCalendar() {
      this.isMenuStartDate = false;
    },

    strToFloat(value) {
      if (value === "") {
        return 0;
      } else {
        const parseResult = parseFloat(value);
        return isNaN(parseResult) ? 0 : parseResult;
      }
    },

    getPayload() {
      let createdBy = this.opportunity.createdBy;

      // set createdBy
      if (this.formTitle === "Neue Opportunity") {
        createdBy = this.$store.state.userInfo.fullname;
      }

      let history = [
        {
          //TODO newversion/clone current user
          modifiedBy: createdBy,
          modifiedAt: new Date().toISOString(),
          comment: this.opportunity.description,
          state: this.getStateByOrderNumber(1),
        },
      ];

      return {
        id: "00000000-0000-0000-0000-000000000000",
        createdAt: null,
        creatorUserEmail: this.$store.state.userInfo.email,
        createdBy: "",
        modifiedAt: null,
        // Caution - task by backend
        modifiedBy: "",
        name: this.opportunity.name,
        customerName: this.customer.name,
        state: "",
        // Inserted in history comment
        description: "",
        totalCost: this.strToFloat(this.opportunity.totalCost),
        stateDescription: "",
        startDate: this.opportunity.startDate,
        duration: this.strToFloat(this.opportunity.duration),
        isEdited: false,
        version: this.opportunity.version,
        cC_KAM: this.customer.cC_KAM,
        cisco_Emails: this.customer.cisco_Emails,
        history: history,
        isSecret: this.opportunity.isSecret,
        parentOpportunityId: this.opportunity.parentOpportunityId
      };
    },

    async close() {
      // Set default opportunity
      this.opportunity = Object.assign({}, this.defaultItem);
      // Set default customer
      this.customer = Object.assign({});

      this.dialog = false;
    },

    validate() {
      this.isValidForm = this.$refs.form.validate();
      this.isValidServices = this.$refs.serviceCard.showNoServicesWarning();
    },

    openDialogSendRequest() {
      this.validate();

      if (this.isValid) {
        this.dialogSendRequest = true;
      }
    },

    async save() {
      let newOpportunity = null;
      const payload = this.getPayload();

      newOpportunity = await opportunityService.post(payload);


      await this.$refs.serviceCard.saveChanges(newOpportunity.id);

      this.$nextTick(() => {
        this.opportunity = Object.assign({}, this.defaultItem);
      });

      this.dialogSendRequest = false;
      this.$store.dispatch("oppAndService/getAll");
      this.close();
    },
  },
};
</script>

<style lang="scss">
.secret {
  position: relative;
  top: 5px;

  .v-messages {
    display: none;
  }
}


.alertText {
  width: 100%;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
}
</style>