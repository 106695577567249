var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            [
              _vm._v(" Dienstleistungen "),
              _c(
                "div",
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(" mdi-information-outline "),
                  ]),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.alert,
                          expression: "!alert",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.alert = !_vm.alert
                        },
                      },
                    },
                    [_vm._v(" mdi-arrow-down-drop-circle-outline ")]
                  ),
                  _c(
                    "v-icon",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.alert,
                          expression: "alert",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          _vm.alert = !_vm.alert
                        },
                      },
                    },
                    [_vm._v(" mdi-arrow-up-drop-circle-outline ")]
                  ),
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        value: _vm.alert,
                        border: "top",
                        "colored-border": "",
                        type: "info",
                        elevation: "1",
                      },
                    },
                    [
                      _c("div", { staticClass: "grid-container" }, [
                        _c(
                          "div",
                          { staticClass: "grid-item-header-skillname" },
                          [_vm._v("Dienstleistung")]
                        ),
                        _c("div", { staticClass: "grid-item-header" }, [
                          _vm._v("bis 40 PT"),
                        ]),
                        _c("div", { staticClass: "grid-item-header" }, [
                          _vm._v("41 bis 160 PT"),
                        ]),
                        _c("div", { staticClass: "grid-item-header" }, [
                          _vm._v("mehr als 160 PT"),
                        ]),
                      ]),
                      _vm._l(_vm.skills, function (skill) {
                        return _c("div", { key: skill.id }, [
                          _c("div", { staticClass: "grid-container" }, [
                            _c("div", { staticClass: "grid-item-skillname" }, [
                              _vm._v(" " + _vm._s(skill.name) + " "),
                            ]),
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      skill.lessThan40PT,
                                      "0,0.00 $ "
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      skill.between41To160PT,
                                      "0,0.00 $ "
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "grid-item" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      skill.moreThan160PT,
                                      "0,0.00 $ "
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { md: "6", sm: "6" } },
                [
                  _c("sx-currency-input", {
                    attrs: {
                      disabled: true,
                      readonly: true,
                      label: "Gesamtsumme",
                      "data-cy": "skills-totalsum",
                    },
                    model: {
                      value: _vm.totalSum,
                      callback: function ($$v) {
                        _vm.totalSum = $$v
                      },
                      expression: "totalSum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "mt-20" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditing,
                      expression: "!isEditing",
                    },
                  ],
                  ref: "serviceInformation",
                  staticClass: "mt-3",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", sm: "4", md: "4" },
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              disabled: _vm.isEditing,
                              items: _vm.$store.getters["skill/getSkillsName"],
                              rules: [
                                function (v) {
                                  return !!v || "Feld darf nicht leer sein."
                                },
                              ],
                              label: "Qualifikation *",
                              "data-cy": "skill-name",
                              outlined: "",
                              dense: "",
                            },
                            on: {
                              input: function () {
                                return _vm.updatePrices(true)
                              },
                            },
                            model: {
                              value: _vm.editServiceItem.skillName,
                              callback: function ($$v) {
                                _vm.$set(_vm.editServiceItem, "skillName", $$v)
                              },
                              expression: "editServiceItem.skillName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 skill-col",
                          attrs: { cols: "12", sm: "2", md: "2" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              suffix: "PT",
                              disabled: _vm.isEditing,
                              label: "Tage *",
                              rules: [
                                function (v) {
                                  return (
                                    /^\d+$/.test(v) ||
                                    "Es sind nur Zahlen erlaubt."
                                  )
                                },
                              ],
                              "data-cy": "skill-days",
                              outlined: "",
                              "single-line": "",
                              dense: "",
                            },
                            on: {
                              input: function () {
                                return _vm.updatePrices(false)
                              },
                            },
                            model: {
                              value: _vm.days,
                              callback: function ($$v) {
                                _vm.days = $$v
                              },
                              expression: "days",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", sm: "3", md: "3" },
                        },
                        [
                          _c("sx-currency-input", {
                            attrs: {
                              disabled: _vm.isEditing,
                              label: "Tagessatz",
                            },
                            on: {
                              change: function () {
                                return _vm.updatePrices(false)
                              },
                              "click:clear": function ($event) {
                                return _vm.clear()
                              },
                            },
                            model: {
                              value: _vm.editServiceItem.pricePerDay,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editServiceItem,
                                  "pricePerDay",
                                  $$v
                                )
                              },
                              expression: "editServiceItem.pricePerDay",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", sm: "3", md: "3" },
                        },
                        [
                          _c("sx-currency-input", {
                            attrs: {
                              disabled: _vm.isEditing,
                              rules: _vm.ruleRequiredText,
                              label: "Summe",
                            },
                            on: { change: _vm.sumChanged },
                            model: {
                              value: _vm.editServiceItem.sum,
                              callback: function ($$v) {
                                _vm.$set(_vm.editServiceItem, "sum", $$v)
                              },
                              expression: "editServiceItem.sum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right mr-3 mb-5",
                              attrs: { cols: "12", sm: "3", md: "3" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    height: "2.5rem",
                                    color: "blue darken-1",
                                    text: "",
                                    "data-cy": "add-skill-btn",
                                    outlined: "",
                                  },
                                  on: { click: _vm.addServiceItem },
                                },
                                [_vm._v("Hinzufügen")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500px" },
                  model: {
                    value: _vm.deleteDialogVisible,
                    callback: function ($$v) {
                      _vm.deleteDialogVisible = $$v
                    },
                    expression: "deleteDialogVisible",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "title" }, [
                        _vm._v(" Soll das Element gelöscht werden? "),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: { click: _vm.onCloseConfirmDeleteDialog },
                            },
                            [_vm._v("Zurück")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "blue darken-1", text: "" },
                              on: { click: _vm.onDeleteServiceConfirmed },
                            },
                            [_vm._v("JA")]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "px-0",
                  attrs: { cols: "12", sm: "12", md: "12" },
                },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      loading: _vm.isLoading,
                      "loading-text": "Dienstleistungen werden geladen...",
                      headers: [
                        {
                          text: "Qualifikation",
                          align: "start",
                          sortable: false,
                          value: "skillName",
                        },
                        { text: "Tage", value: "days" },
                        { text: "Tagessatz", value: "pricePerDay" },
                        { text: "Summe", value: "sum" },
                        //{ text: 'SKU', value: 'skuName' },
                        {
                          text: "Aktionen",
                          value: "actions",
                          sortable: false,
                        },
                      ],
                      items: _vm.opportunityServices,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.pricePerDay",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatNumber")(
                                    item.pricePerDay,
                                    "0,0.00 $ "
                                  )
                                )
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.sum",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatNumber")(
                                      item.sum,
                                      "0,0.00 $ "
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        !_vm.isEditing
                          ? {
                              key: "item.actions",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: { "max-width": "400px" },
                                      model: {
                                        value: _vm.editServiceDialogVisible,
                                        callback: function ($$v) {
                                          _vm.editServiceDialogVisible = $$v
                                        },
                                        expression: "editServiceDialogVisible",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Dienstleistung"),
                                          ]),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "6",
                                                    md: "6",
                                                  },
                                                },
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.$store.getters[
                                                          "skill/getSkillsName"
                                                        ],
                                                      label: "Dienstleistungen",
                                                      outlined: "",
                                                      dense: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.editServiceItem
                                                          .skillName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editServiceItem,
                                                          "skillName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editServiceItem.skillName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "blue darken-1",
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.editServiceDialogVisible = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("Zurück")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "blue darken-1",
                                                    text: "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    click: _vm.addServiceItem,
                                                  },
                                                },
                                                [_vm._v("Speichern")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showConfirmDeleteDialog(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-delete")]
                                  ),
                                ]
                              },
                            }
                          : null,
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                { attrs: { outlined: "", disabled: "" } },
                                [
                                  _c("span", [
                                    _vm._v("Keine Dienstleistungen *"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "sx-dialog",
            { ref: "warning", attrs: { title: "Warnung" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    outlined: "",
                    type: "error",
                    prominent: "",
                    border: "left",
                    "data-cy": "no-skills-warning",
                  },
                },
                [
                  _vm._v(
                    " Es wurde noch keine Dienstleistungen hingefügt! Bitte Dienstleistungen hinzufügen! "
                  ),
                ]
              ),
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "blue darken-1",
                        "data-cy": "close-no-skills-warning",
                        outlined: "",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return function () {
                            return _vm.$refs.warning.close()
                          }.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Zurück")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }