var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-simple-table", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("thead", [
                  _c("tr", { staticClass: "br" }, [
                    _c("th"),
                    _c("th", { staticClass: "text-left text-header" }, [
                      _vm._v("Bearbeitet von..."),
                    ]),
                    _c("th", { staticClass: "text-left text-header" }, [
                      _vm._v("Datum"),
                    ]),
                    _c("th", { staticClass: "text-left text-header" }, [
                      _vm._v("Gesamtsumme"),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", { staticClass: "br" }, [
                    _c(
                      "td",
                      [
                        _c("v-checkbox", {
                          attrs: { label: "Neue Opp erstellt", disabled: "" },
                          model: {
                            value: _vm.newOpp,
                            callback: function ($$v) {
                              _vm.newOpp = $$v
                            },
                            expression: "newOpp",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.historyState.createdBy))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.formatDate(_vm.historyState.createdAt))
                      ),
                    ]),
                    _c("td"),
                  ]),
                  _c("tr", { staticClass: "br" }, [
                    _c(
                      "td",
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { column: "" },
                            model: {
                              value: _vm.historyState.qualifiedReject,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.historyState,
                                  "qualifiedReject",
                                  $$v
                                )
                              },
                              expression: "historyState.qualifiedReject",
                            },
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                label: "Opp qualifiziert",
                                value: "qualifiziert",
                                disabled: "",
                              },
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: "Opp abgelehnt",
                                value: "abgelehnt",
                                disabled: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(_vm.historyState.modifiedByQual))]),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.formatDate(_vm.historyState.modifiedAtQual))
                      ),
                    ]),
                    _c("td", [
                      _vm.historyState.qualifiedReject
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatNumber")(
                                  _vm.opportunity.totalCost,
                                  "0,0.00 $ "
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "tr",
                    { staticClass: "br" },
                    [
                      _c(
                        "td",
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: "Bitte um Freigabe",
                              disabled: !_vm.canSetRequest,
                            },
                            on: {
                              click: function ($event) {
                                _vm.dialogRelease = true
                              },
                            },
                            model: {
                              value: _vm.historyState.isBitteFreigabe,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.historyState,
                                  "isBitteFreigabe",
                                  $$v
                                )
                              },
                              expression: "historyState.isBitteFreigabe",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.historyState.modifiedByBitteFreigabe)
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(
                              _vm.historyState.modifiedAtBitteFreigabe
                            )
                          )
                        ),
                      ]),
                      _c("td"),
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "500" },
                          model: {
                            value: _vm.dialogRelease,
                            callback: function ($$v) {
                              _vm.dialogRelease = $$v
                            },
                            expression: "dialogRelease",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", {
                                staticClass: "title sx-vertical-layout",
                                staticStyle: { color: "red" },
                                attrs: {
                                  "data-cy": "send-updated-opportunity-dialog",
                                },
                              }),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-center" },
                                    [
                                      _vm._v(
                                        " Ich möchte um eine Freigabe bitten"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: { click: _vm.closeDialogRelease },
                                    },
                                    [_vm._v(" Zurück ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        text: "",
                                        "data-cy":
                                          "sure-to-send-updated-opportunity",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.checkQualifiedState(
                                            _vm.opportunity
                                          )
                                          _vm.checkRelease = "release"
                                        },
                                      },
                                    },
                                    [_vm._v(" E-Mail öffnen ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "tr",
                    { staticClass: "br" },
                    [
                      _c(
                        "td",
                        [
                          _vm.opportunity.contractName === "UCC 21587"
                            ? _c(
                                "div",
                                { staticClass: "uniqueId-container" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Freigabe erteilt",
                                      disabled: !_vm.canSetFreigabe,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogIsFreigabe = true
                                      },
                                    },
                                    model: {
                                      value: _vm.historyState.isFreigabe,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.historyState,
                                          "isFreigabe",
                                          $$v
                                        )
                                      },
                                      expression: "historyState.isFreigabe",
                                    },
                                  }),
                                  _c("span", { staticClass: "approvalId" }, [
                                    _vm._v(
                                      "U-ID: " +
                                        _vm._s(_vm.opportunity.approvalId)
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _c("v-checkbox", {
                                attrs: {
                                  label: "Freigabe erteilt",
                                  disabled: !_vm.canSetFreigabe,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogIsFreigabe = true
                                  },
                                },
                                model: {
                                  value: _vm.historyState.isFreigabe,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.historyState,
                                      "isFreigabe",
                                      $$v
                                    )
                                  },
                                  expression: "historyState.isFreigabe",
                                },
                              }),
                        ],
                        1
                      ),
                      _c("td", [
                        _vm._v(_vm._s(_vm.historyState.modifiedByFreigabe)),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.formatDate(_vm.historyState.modifiedAtFreigabe)
                          )
                        ),
                      ]),
                      _c("td"),
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "500" },
                          model: {
                            value: _vm.dialogIsFreigabe,
                            callback: function ($$v) {
                              _vm.dialogIsFreigabe = $$v
                            },
                            expression: "dialogIsFreigabe",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass: "headline",
                                  attrs: {
                                    "data-cy":
                                      "send-updated-opportunity-dialog",
                                  },
                                },
                                [
                                  _vm.opportunity.contractName === "UCC 21587"
                                    ? _c("span", [
                                        _vm._v("Unique ID eintragen"),
                                      ])
                                    : _c("span", [
                                        _vm._v("Freigabe bestätigen"),
                                      ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-3 py-0",
                                      on: { click: _vm.closeDialogIsFreigabe },
                                    },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _vm.opportunity.contractName === "UCC 21587"
                                ? _c(
                                    "v-card-text",
                                    { staticClass: "pt-5 pb-0" },
                                    [
                                      _vm._v(
                                        " Bitte geben Sie die Unique ID ein: "
                                      ),
                                      _c(
                                        "v-form",
                                        {
                                          model: {
                                            value: _vm.isFormValid,
                                            callback: function ($$v) {
                                              _vm.isFormValid = $$v
                                            },
                                            expression: "isFormValid",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Unique ID *",
                                              rules: _vm.ruleEightDigit,
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.approvalId,
                                              callback: function ($$v) {
                                                _vm.approvalId = $$v
                                              },
                                              expression: "approvalId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-card-text",
                                    { staticClass: "pt-5 pb-0 mb-3" },
                                    [
                                      _vm._v(
                                        " Wollen Sie die Opportunity freigeben? "
                                      ),
                                    ]
                                  ),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "blue darken-1",
                                        text: "",
                                        outlined: "",
                                      },
                                      on: { click: _vm.closeDialogIsFreigabe },
                                    },
                                    [_vm._v(" Abbrechen ")]
                                  ),
                                  _vm.opportunity.contractName === "UCC 21587"
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: "",
                                            outlined: "",
                                            disabled: !_vm.isFormValid,
                                            "data-cy":
                                              "sure-to-send-updated-opportunity",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFreigabeChecked(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Angebot freigeben ")]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "blue darken-1",
                                            text: "",
                                            outlined: "",
                                            "data-cy":
                                              "sure-to-send-updated-opportunity",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFreigabeChecked(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Angebot freigeben ")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("tr", { staticClass: "br" }, [
                    _c(
                      "td",
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { column: "" },
                            on: {
                              change: function ($event) {
                                return _vm.onWonLostChanged($event)
                              },
                            },
                            model: {
                              value: _vm.historyState.wonLost,
                              callback: function ($$v) {
                                _vm.$set(_vm.historyState, "wonLost", $$v)
                              },
                              expression: "historyState.wonLost",
                            },
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                label: "Gewonnen",
                                value: "gewonnen",
                                disabled: !_vm.canChangeWonLost,
                              },
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: "Verloren",
                                value: "verloren",
                                disabled: !_vm.canSetLost,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [
                      _vm._v(_vm._s(_vm.historyState.modifiedByWonLost)),
                    ]),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.formatDate(_vm.historyState.modifiedAtWonLost)
                        )
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.historyState.wonLost == "gewonnen",
                              expression: "historyState.wonLost == 'gewonnen'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatNumber")(
                                _vm.realTotalCost,
                                "0,0.00 $ "
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.historyState.wonLost == "verloren",
              expression: "historyState.wonLost == 'verloren'",
            },
          ],
        },
        [
          _c("v-textarea", {
            staticStyle: { width: "100%", "margin-top": "25px" },
            attrs: {
              label: "Kommentar Computacenter",
              disabled: _vm.getCiscoGroup ? true : false || !_vm.canSetLost,
              outlined: "",
              dense: "",
            },
            model: {
              value: _vm.lostComment,
              callback: function ($$v) {
                _vm.lostComment = $$v
              },
              expression: "lostComment",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }