<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr class="br">
            <th></th>
            <th class="text-left text-header">Bearbeitet von...</th>
            <th class="text-left text-header">Datum</th>
            <th class="text-left text-header">Gesamtsumme</th>
          </tr>
        </thead>
        <tbody>
          <tr class="br">
            <td>
              <v-checkbox v-model="newOpp" label="Neue Opp erstellt" disabled></v-checkbox>
            </td>
            <td>{{ historyState.createdBy }}</td>
            <td>{{ formatDate(historyState.createdAt) }}</td>
            <td></td>
          </tr>
          <tr class="br">
            <td>
              <v-radio-group column v-model="historyState.qualifiedReject">
                <v-radio label="Opp qualifiziert" value="qualifiziert" disabled></v-radio>
                <v-radio label="Opp abgelehnt" value="abgelehnt" disabled></v-radio>
              </v-radio-group>
            </td>
            <td>{{ historyState.modifiedByQual }}</td>
            <td>{{ formatDate(historyState.modifiedAtQual) }}</td>
            <td>
              <span v-if="historyState.qualifiedReject">{{
                opportunity.totalCost | formatNumber("0,0.00 $ ")
              }}</span>
            </td>
          </tr>
          <tr class="br">
            <td>
              <v-checkbox v-model="historyState.isBitteFreigabe" label="Bitte um Freigabe" :disabled="!canSetRequest"
                @click="dialogRelease = true"></v-checkbox>
            </td>
            <td>{{ historyState.modifiedByBitteFreigabe }}</td>
            <td>{{ formatDate(historyState.modifiedAtBitteFreigabe) }}</td>
            <td></td>

            <v-dialog v-model="dialogRelease" width="500">
              <v-card>
                <v-card-title class="title sx-vertical-layout" style="color: red"
                  data-cy="send-updated-opportunity-dialog">
                </v-card-title>
                <v-card-text>
                  <v-card-text class="text-center">
                    Ich möchte um eine Freigabe bitten</v-card-text>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDialogRelease">
                    Zurück
                  </v-btn>
                  <v-btn color="primary" text @click="
                    checkQualifiedState(opportunity);
                  checkRelease = 'release';
                  " data-cy="sure-to-send-updated-opportunity">
                    E-Mail öffnen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </tr>
          <tr class="br">
            <td>
              <div class="uniqueId-container" v-if="opportunity.contractName === 'UCC 21587'">
                <v-checkbox @click="dialogIsFreigabe = true" v-model="historyState.isFreigabe" label="Freigabe erteilt"
                  :disabled="!canSetFreigabe"></v-checkbox>
                <span class="approvalId">U-ID: {{ opportunity.approvalId }}</span>
              </div>
              <v-checkbox @click="dialogIsFreigabe = true" v-model="historyState.isFreigabe" label="Freigabe erteilt"
                :disabled="!canSetFreigabe" v-else></v-checkbox>
            </td>
            <td>{{ historyState.modifiedByFreigabe }}</td>
            <td>{{ formatDate(historyState.modifiedAtFreigabe) }}</td>
            <td></td>

            <v-dialog v-model="dialogIsFreigabe" width="500">
              <v-card>
                <v-card-title class="headline" data-cy="send-updated-opportunity-dialog">
                  <span v-if="opportunity.contractName === 'UCC 21587'">Unique ID eintragen</span>
                  <span v-else>Freigabe bestätigen</span>
                  <v-spacer></v-spacer>
                  <v-icon @click="closeDialogIsFreigabe" class="ml-3 py-0">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="pt-5 pb-0" v-if="opportunity.contractName === 'UCC 21587'">
                  Bitte geben Sie die Unique ID ein:
                  <v-form v-model="isFormValid">
                    <v-text-field label="Unique ID *" v-model="approvalId" :rules="ruleEightDigit"
                      clearable></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-text class="pt-5 pb-0 mb-3" v-else>
                  Wollen Sie die Opportunity freigeben?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text outlined @click="closeDialogIsFreigabe">
                    Abbrechen
                  </v-btn>
                  <v-btn color="blue darken-1" text outlined :disabled="!isFormValid" @click="onFreigabeChecked($event)"
                    data-cy="sure-to-send-updated-opportunity" v-if="opportunity.contractName === 'UCC 21587'">
                    Angebot freigeben
                  </v-btn>
                  <v-btn color="blue darken-1" text outlined @click="onFreigabeChecked($event)"
                    data-cy="sure-to-send-updated-opportunity" v-else>
                    Angebot freigeben
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </tr>
          <tr class="br">
            <td>
              <v-radio-group column v-model="historyState.wonLost" @change="onWonLostChanged($event)">
                <v-radio label="Gewonnen" value="gewonnen" :disabled="!canChangeWonLost"></v-radio>
                <v-radio label="Verloren" value="verloren" :disabled="!canSetLost"></v-radio>
              </v-radio-group>
            </td>
            <td>{{ historyState.modifiedByWonLost }}</td>
            <td>{{ formatDate(historyState.modifiedAtWonLost) }}</td>
            <td>
              <span v-show="historyState.wonLost == 'gewonnen'">{{
                realTotalCost | formatNumber("0,0.00 $ ")
              }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row v-show="historyState.wonLost == 'verloren'">
      <v-textarea style="width: 100%; margin-top: 25px" label="Kommentar Computacenter" v-model="lostComment"
        :disabled="getCiscoGroup ? true : false || !canSetLost" outlined dense></v-textarea>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { opportunityService } from "@/services";
import { HISTORY_STATE } from "../../helper/historyStates";

export default {
  name: "Script",

  props: {
    value: Object,
    opportunity: Object,
    realTotalCost: Number,
  },

  data() {
    return {
      newOpp: true,
      historyState: {},
      HISTORY_STATE: HISTORY_STATE,
      state: {},

      qualifiedReject: "",
      checkRelease: "",
      released: false,

      dialogDelete: false,

      dialogRelease: false,
      dialogIsFreigabe: false,

      dialogSendRequest: false,

      foundIsBitteUmFreigabe: null,
      newHistoryStates: [],

      lostComment: "",

      ruleEightDigit: [
        (value) => !!value || "Das Feld darf nicht leer sein.",
        (value) =>
          /^\d{8}$/.test(value) ||
          "Unique ID muss aus 8 Ziffern bestehen.",
      ],
      isFormValid: false,
      approvalId: ""
    };
  },

  mounted() {
    this.onHistoryStateChanged();
  },

  watch: {
    opportunity() {
      this.onHistoryStateChanged();
    },

    lostComment(val) {
      this.$emit("onLostComment", val);
    },
  },

  computed: {
    ...mapGetters("user", [
      "getComputacenterGroup",
      "getCiscoGroup",
      "getAdminGroup",
      "getCiscoSibeGroup"
    ]),

    canChangeWonLost: {
      get() {
        if (this.getCiscoGroup) return false;
        if (this.getCiscoSibeGroup) return false;
        if (this.opportunity == null) return false;
        if (this.historyState.isErsetzt) return false;
        if (this.opportunity.history == null) return false;
        if (this.getComputacenterGroup && this.historyState.isFreigabe) return true;

        return false;
      },
    },

    canSetLost: {
      get() {
        if (this.getCiscoGroup) return false;
        if (this.getCiscoSibeGroup) return false;
        if (this.opportunity == null) return false;
        if (this.opportunity.history == null) return false;
        if (this.historyState.isErsetzt) return false;
        if (this.getComputacenterGroup) return true;

        return false;
      },
    },

    canSetRequest() {
      if (!this.getComputacenterGroup) return false;
      if (this.historyState.wonLost == "verloren") return false;
      if (this.historyState.isFreigabe) return false;
      if (
        !this.historyState.isBitteFreigabe &&
        this.historyState.isQualifiziert
      )
        return true;
      return false;
    },

    canSetFreigabe() {
      if (this.getComputacenterGroup) return false;
      if (this.historyState.isErsetzt) return false;

      if (this.getCiscoGroup) {
        if (this.historyState.wonLost == "verloren") return false;
        if (this.historyState.isFreigabe) return false;
        if (this.historyState.isBitteFreigabe || this.historyState.isQualifiziert)
          return true;
      }

      return false;
    },

    /*     canSetSecretFreigabe() {
          if (this.getComputacenterGroup) return false;
    
          if (this.getCiscoSibeGroup) {
            if (this.historyState.wonLost == "verloren") return false;
            if (this.historyState.isFreigabe) return false;
            if (this.historyState.isBitteFreigabe || this.historyState.isQualifiziert)
              return true;
          }
    
          return false;
        }, */

    checkReleaseBox() {
      // only CiscoGroup can set checkbox for released
      // if the checkbox was released than set to read only
      if (this.getCiscoGroup) {
        for (let history of this.opportunity.history) {
          if (
            history.state.orderNumber === 5 ||
            this.opportunity.history.length === HISTORY_STATE.Neu ||
            history.state.orderNumber === 3 ||
            history.state.orderNumber === 6 ||
            history.state.orderNumber === 7 ||
            history.state.orderNumber === 8
          ) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    },

    showActionButtons() {
      if (this.getCiscoGroup) {
        let state = 0;
        for (let history of this.opportunity.history) {
          state = history.state.orderNumber;
        }
        if (state === 2 || state === 4) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapMutations("oppAndService", ["updateOpportunity"]),

    close() {
      this.$emit("emitDialogClose", false);
    },

    getStateByOrderNumber(orderNumber) {
      return this.$store.getters["opportunityState/findByOrderNumber"](
        orderNumber
      );
    },

    async checkQualifiedState(opportunity) {
      let state = "";
      for (let history of opportunity.history) {
        state = history.state.name;
      }
      if (state === "Qualifiziert" && this.getComputacenterGroup) {
        this.checkRelease = "release";

        this.historyState.modifiedByBitteFreigabe =
          this.$store.state.userInfo.fullname;
        this.historyState.modifiedAtBitteFreigabe = new Date().toISOString();

        if (this.getComputacenterGroup) {
          if (this.checkRelease === "release") {
            await opportunityService.patchHistoryRecord(this.opportunity.id, {
              modifiedBy: this.$store.state.userInfo.fullname,
              modifiedAt: new Date().toISOString(),
              state: this.getStateByOrderNumber(4),
            });

            opportunity.history.push({
              modifiedBy: this.$store.state.userInfo.fullname,
              modifiedAt: new Date().toISOString(),
              state: this.getStateByOrderNumber(4),
            });
          }
        }

        setTimeout(() => {
          this.onOpenMail(opportunity);
        }, 2000);
      }
    },

    async onOpenMail(data) {
      let template = this.$store.getters["app/getMailTemplate"]({
        cisco: this.getCiscoGroup,
        cc: this.getComputacenterGroup,
        path: "opp",
        data: data,
        additionalState: null,
      });
      this.$store.dispatch("openMail", {
        template: template,
        scope: data,
      });

      this.close();
    },

    onHistoryStateChanged() {
      let neu = this.getHistory(HISTORY_STATE.Neu);
      let freigabe = this.getHistory(HISTORY_STATE.Freigegeben);
      let bitteFreigabe = this.getHistory(HISTORY_STATE.BitteUmFreigabe);
      let won = this.getHistory(HISTORY_STATE.Gewonnen);
      let lost = this.getHistory(HISTORY_STATE.Verloren);
      let abgelehnt = this.getHistory(HISTORY_STATE.Abgelehnt);
      let qualifiziert = this.getHistory(HISTORY_STATE.Qualifiziert);
      let ersetzt = this.getHistory(HISTORY_STATE.Ersetzt);

      this.historyState = {
        isNeu: neu != null,
        createdBy: (neu || {}).modifiedBy,
        createdAt: (neu || {}).modifiedAt,

        isBitteFreigabe: bitteFreigabe != null,
        modifiedByBitteFreigabe: (bitteFreigabe || {}).modifiedBy,
        modifiedAtBitteFreigabe: (bitteFreigabe || {}).modifiedAt,

        isFreigabe: freigabe != null,
        modifiedByFreigabe: (freigabe || {}).modifiedBy,
        modifiedAtFreigabe: (freigabe || {}).modifiedAt,

        wonLost: won ? "gewonnen" : lost ? "verloren" : null,
        modifiedByWonLost: (won || lost || {}).modifiedBy,
        modifiedAtWonLost: (won || lost || {}).modifiedAt,

        qualifiedReject: qualifiziert
          ? "qualifiziert"
          : abgelehnt
            ? "abgelehnt"
            : null,

        isAbgelehnt: abgelehnt != null,
        isQualifiziert: qualifiziert != null,
        modifiedByQual: (qualifiziert || abgelehnt || {}).modifiedBy,
        modifiedAtQual: (qualifiziert || abgelehnt || {}).modifiedAt,

        isErsetzt: ersetzt != null,
      };

      let foundLostState = this.opportunity.history.find(
        (item) => item.state.orderNumber == HISTORY_STATE.Verloren
      );

      if (foundLostState) {
        this.lostComment = foundLostState.comment;
      }
    },

    onWonLostChanged(value) {
      // Set history state
      this.historyState.modifiedByWonLost = this.$store.state.userInfo.fullname;
      this.historyState.modifiedAtWonLost = new Date().toISOString();

      // add the new record
      switch (value) {
        case "verloren":
          this.state = {
            modifiedBy: this.$store.state.userInfo.fullname,
            modifiedAt: new Date().toISOString(),
            state: this.getStateByOrderNumber(7),
          };
          this.$emit("onWonLostChanged", 1);
          break;
        case "gewonnen":
          this.state = {
            modifiedBy: this.$store.state.userInfo.fullname,
            modifiedAt: new Date().toISOString(),
            state: this.getStateByOrderNumber(6),
          };
          this.$emit("onWonLostChanged", 0);
          break;
      }

      // notify the v-model binding about new history records to push
      this.$emit("input", this.state);
    },

    async onFreigabeChecked() {

      if (this.opportunity.contractName === 'UCC 21587') {
        // Attempt to set the approval ID and check if it was successful
        const isApprovalIdSet = await opportunityService.setApprovalId(
          this.opportunity.id,
          {
            approvalId: this.approvalId
          }
        )

        // If setting the approval ID was not successful, exit the method
        if (!isApprovalIdSet) {
          return; // Early return to prevent further execution
        }
      }

      // Proceed with the rest of the method if the approval ID was successfully set
      this.historyState.modifiedByFreigabe =
        this.$store.state.userInfo.fullname;
      this.historyState.modifiedAtFreigabe = new Date().toISOString();
      this.state = {
        modifiedBy: this.$store.state.userInfo.fullname,
        modifiedAt: new Date().toISOString(),
        state: this.getStateByOrderNumber(5),
      };

      await opportunityService.patchHistoryRecord(
        this.opportunity.id,
        this.state
      );

      this.$emit("input", this.state);
      this.dialogRelease = false;
      this.approvalId = "";

      this.close();
    },

    /**
     * Get an entry of the history array by orderNumber,
     * usage:
     * getHistory(HISTORY_STATE.Neu)
     */
    getHistory(historyState) {
      return this.opportunity.history.find(
        (h) => h.state.orderNumber == historyState
      );
    },

    /**
     * Get a field value from a history state by fieldName,
     * usage:
     * getHistoryParam(HISTORY_STATE.Neu, "modifiedBy")
     */
    getHistoryParam(historyState, fieldName) {
      let entry = this.tryGetHistory(historyState);
      if (entry != null) return entry[fieldName];
      return null;
    },

    formatDate(date) {
      if (!date) return null;

      const datePart = date.split("T")[0];
      const [year, month, day] = datePart.split("-");

      return `${day}.${month}.${year}`;
    },

    closeDialogRelease() {
      this.historyState.isBitteFreigabe = null;
      this.dialogRelease = false;
    },

    closeDialogIsFreigabe() {
      this.historyState.isFreigabe = null;
      this.dialogIsFreigabe = false;
      this.approvalId = "";
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 16px 16px 0 16px !important;
}

.param-data-style {
  padding-left: 16px;
  color: #727272;
}

.replaced-text {
  color: red;
  padding-right: 16px;
  font-size: 14px;
  font-family: Helvetica;
}

.text-header {
  font-weight: bold;
  color: black !important;
  font-size: 13px !important;
}

.check-style {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70px;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.action-buttons {
  flex-direction: row-reverse;
}

.br>td:not(:last-child),
.br>th:not(:last-child) {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}

.uniqueId-container {
  position: relative;

  .approvalId {
    font-weight: bolder;
    position: absolute;
    left: 32px;
    top: 30px;
    color: #727272
  }
}
</style>
