var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "auto", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(_vm._b({}, "v-btn", attrs, false), on),
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v("mdi-exclamation-thick"),
                  ]),
                  _vm._v("Fehler melden "),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-icon", { attrs: { color: "error" } }, [
                _vm._v("mdi-exclamation-thick"),
              ]),
              _vm._v(" Fehler melden "),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  staticClass: "ml-3 py-0",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-5 pb-0" }, [
            _c("div", [
              _c("p", [
                _vm._v("Gibt es Fehler in der Bedienung der Datenbank? "),
              ]),
              _c("p", [
                _vm._v(
                  "Gibt es Änderungen bei den Verantwortlichen der Kunden?"
                ),
              ]),
              _c("p", [_vm._v("Gibt es Wünsche oder Anregungen?")]),
            ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", outlined: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Zurück ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    outlined: "",
                    text: "",
                    href: _vm.mailTo,
                  },
                },
                [
                  _vm._v("E-mail senden "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v("mdi-send"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }