var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-dialog", {
    attrs: { persistent: "", "max-width": "45rem", scrollable: "" },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function (ref) {
          var on = ref.on
          var attrs = ref.attrs
          return [
            _c(
              "span",
              _vm._g(
                _vm._b(
                  {
                    staticClass: "mr-1 version",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        _vm.dialogSendRequest = true
                      },
                    },
                  },
                  "span",
                  attrs,
                  false
                ),
                on
              ),
              [_vm._v("VS")]
            ),
            _vm.dialog
              ? _c(
                  "v-card",
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _vm.dialogSendRequest
                            ? _c(
                                "v-dialog",
                                {
                                  attrs: { width: "550" },
                                  model: {
                                    value: _vm.dialogSendRequest,
                                    callback: function ($$v) {
                                      _vm.dialogSendRequest = $$v
                                    },
                                    expression: "dialogSendRequest",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "title" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "icon-center",
                                              attrs: { color: "red" },
                                            },
                                            [_vm._v("mdi-alert-outline")]
                                          ),
                                          _vm._v(
                                            " Wollen Sie eine Opportunity Version erstellen? "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.close },
                                            },
                                            [_vm._v(" Zurück ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: { click: _vm.save },
                                            },
                                            [_vm._v(" Ja ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                )
              : _vm._e(),
          ]
        },
      },
    ]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v
      },
      expression: "dialog",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }